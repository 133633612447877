import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersComponent } from './users/users.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { CoreCommonModule } from '@core/common.module';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TranslateModule } from '@ngx-translate/core';
import { CompaniesComponent } from './companies/companies.component';
import { DevicesComponent } from './devices/devices.component';
import { CsvModule } from '@ctrl/ngx-csv';
import { AdminService } from 'app/services/admin.service';
import { DeviceServiceService } from 'app/services/device-service.service';
import { UserServiceService } from 'app/services/user-service.service';
import { BusinessServiceService } from 'app/services/business-service.service';
import { LogComponent } from './log/log.component';
import { NgxMaskModule } from 'ngx-mask';
import { FormsModule } from '@angular/forms';
import { NgApexchartsModule } from 'ng-apexcharts';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { UserSettingComponent } from './user-setting/user-setting.component';
import {NgSelectModule} from '@ng-select/ng-select';
import { LogV2Component } from 'app/log-v2/log-v2.component';
import { DatatablesService } from 'app/services/datatables.service';
import { AuthGuard } from 'app/services/guard/auth.guard';
import { LogDetailV2Component } from 'app/log-detail-v2/log-detail-v2.component';
import { ToastrModule } from 'ngx-toastr';
 const routes = [
  {
    path: 'log/:idPhone',
    component: LogComponent,
    data: { animation: 'datatables' },
    canActivate: [AuthGuard]
  },
  {
    path: 'log-v2',
    component: LogV2Component, canActivate : [AuthGuard],
    resolve:{
      datatables: DatatablesService
    },
  },
  {
    path: 'log-v2/detail/:idAddress/user/:idPhone',
    component: LogDetailV2Component,
    data: { animation: 'datatables' },
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    component: UsersComponent,
    resolve: {
      datatables: UserServiceService
    },
    data: { animation: 'data,tables' },
    canActivate: [AuthGuard]
  },
  {
    path: 'companies',
    component: CompaniesComponent,
    resolve: {
      datatables: BusinessServiceService
    },
    data: { animation: 'sample' },
    canActivate: [AuthGuard]
  },
  {
    path: 'devices',
    component: DevicesComponent,
    resolve: {
      datatables: DeviceServiceService
    },
    data: { animation: 'datatables' },
    canActivate: [AuthGuard]
  },
  {
    path: 'user-setting',
    component: UserSettingComponent,
    resolve: {
      datatables: DeviceServiceService
    },
    data: { animation: 'datatables' },
    canActivate: [AuthGuard]
  },
  
];


@NgModule({
  declarations: [
    CompaniesComponent,
    DevicesComponent,
    UsersComponent,
    LogComponent,
    UserSettingComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ContentHeaderModule,
    TranslateModule,
    CoreCommonModule,
    CardSnippetModule,
    NgxDatatableModule,
    NgbModule,
    CsvModule,
    NgxMaskModule.forRoot(),
    FormsModule,
    NgApexchartsModule,
    Ng2FlatpickrModule,
    ToastrModule.forRoot(),
    NgSelectModule
  ]
})
export class AdminModule { }
