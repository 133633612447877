<!-- Select Sizing start -->
<div class="content-wrapper container-xxl p-0">

  <section class="select-sizing">
    <div class="row match-height">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
 
              <div class="col-10">
                <label>Empresa</label>
                <div class="form-group">
                  <!-- select size large -->
                  <ng-select class="ng-select-size-lg" #SelectSizeLarge [items]="selectGroup" [searchable]="false"
                    bindLabel="name" bindValue="name" (change)="changeBusiness($event)">
                    <ng-template ng-header-tmp>
                      <input class="form-control w-100" type="text"
                        (input)="SelectSizeLarge.filter($event.target.value)" />
                    </ng-template>
                    <!--/ select size large -->
                  </ng-select>
                </div>
              </div>
              <div class="col-2">
                <div class="avatar bg-light-info p-50 m-0">
                  <div class="avatar-content">
                     <a href="javascript:void(0)" (click)="viewCalendar()"><i data-feather="calendar" class="font-medium-5"></i></a>
                  </div>
                </div>
               
                <div *ngIf="show" >
                  <ngb-datepicker #d1 [(ngModel)]="dateSelected" #c1="ngModel" (ngModelChange)="change($event)">
                  </ngb-datepicker>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>


    </div>
  </section>
  <!-- Select Sizing end -->
  <!-- Resume Sleep Datatables -->
  <section id="ngx-datatable-kitchen-sink">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">{{dateSelected}} - {{selectGroupselected}} <code>{{pinCodeValue}}</code></h4>
          </div>
          <div class="card-body pb-1">


            <div class="row">
              <div class="col-12 mb-50">

                <a csvLink [data]="exportCSVData" class="btn btn-outline-secondary float-right mr-1" rippleEffect>Export
                  CSV</a>
              </div>
              <div class="col-md-6 col-12">
                <div class="d-flex justify-content-between align-items-center m-1">
                  <label class="d-flex align-items-center">Show
                    <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    Regitros</label>
                </div>
              </div>
              <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                  <label class="d-flex align-items-center">Buscar<input type="search" placeholder="Search"
                      class="form-control ml-25" (keyup)="filterUpdate($event)" /></label>
                </div>
              </div>
            </div>
            <ngx-datatable 
               [rows]="rows" 
              
               
               [rowHeight]="58" class="bootstrap core-bootstrap" 
               [limit]="10"
              [columnMode]="ColumnMode.force" 
              [headerHeight]="40" 
              [footerHeight]="50"
               [scrollbarH]="true"
              [limit]="basicSelectedOption" 
             
              >
               
               >

              <ngx-datatable-column name="Phone" prop="token" [width]="150">
                <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                  <div class="d-flex align-items-center">

                    <div class="media">
                      <div class="avatar bg-light-primary rounded">
                        <div class="avatar-content">
                          <i data-feather="smartphone" class="avatar-icon font-medium-3"></i>
                        </div>
                      </div>
                      <div class="media-body">
                        <h6 class="transaction-title"> {{ row.manufacturer }}</h6>
                        <small>{{row.model}} </small>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Email/Name" [width]="200">
                <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                  <p style="font-size: 10px;">{{row.email}}</p>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="watch" [width]="200">
                <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>

                  <button type="button" class="btn btn-outline-info" (click)="modalOpenInfo(modalInfo,row.watch)"
                    rippleEffect>
                    Device
                  </button>

                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Sleep" [width]="220">
                <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>

                  <ul>
                    <li style="height: 22px;" *ngFor="let watch of row.watch">
                      <div class="d-flex align-items-center">
                        <div class="row">
                          <div class="col-md-6">
                            <div *ngIf="watch.resume; else elseBlock">
                              <div class="media">
                                <a href="javascript:void(0)" (click)="modalOpenInfoData(modalInfoDataSleep,row.watch)">
                                  <img src="assets/images/moons/{{watch.resume.moon}}" width="40" alt="sleep">
                                </a>
                                <div class="media-body">
                                  <div class="row">
                                    <div class="col-6">
                                      <p class="font-medium-5 font-weight-bold ">{{watch.resume.hh}} <small> h</small>
                                      </p>
                                    </div>
                                    <div class="col-6">
                                      <p class="font-medium-5 font-weight-bold ">{{watch.resume.mm}} <small> min</small>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <ng-template #elseBlock>
                              <div class="media">
                                <img src="assets/images/moons/moon.png" width="40" alt="sleep">

                                <div class="media-body">
                                  <div class="row">
                                    <div class="col-6">
                                      <p class="font-medium-5 font-weight-bold ">0 <small> h</small>
                                      </p>
                                    </div>
                                    <div class="col-6">
                                      <p class="font-medium-5 font-weight-bold ">0 <small> min</small></p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-template>

                          </div>
                        </div>
                      </div>
                    </li>

                  </ul>
                </ng-template>
              </ngx-datatable-column>

            </ngx-datatable>

          </div>
        </div>
      </div>
    </div>

  </section>
  <!--/ Resume sleep Datatables -->
 

</div>


<!-- Modal Info waches-->
<ng-template #modalInfo let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="myModalLabel160">Informacion de Reloj</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>

    <ul>
      <li *ngFor="let watch of rowselect">


        <div class="align-items-center">
          <div *ngIf="watch; else noWatch">
            <div class="row">
              <div class="col-md-6">
                <div class="media">
                  <div class="avatar bg-light-warning rounded">
                    <span class="avatar-content" style="height: 45px;">
                      <i data-feather="watch" class="avatar-icon font-medium-3"></i>
                    </span>
                    <div *ngIf="watch.stateString == 'Conectado'">
                      <span class="avatar-status-online"></span>
                    </div>
                    <div *ngIf="watch.stateString == 'Conectando…'">
                      <span class="avatar-status-offline"></span>
                    </div>
                    <div *ngIf="watch.stateString == 'No conectado'">
                      <span class="avatar-status-busy"></span>
                    </div>
                  </div>

                  <div class="media-body">
                    <small class="font-weight-bolder">{{watch.address}}</small><br>
                    <small class="mb-0">{{watch.stateString}}</small><br>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <small><i data-feather="database"></i> {{watch.lastSyncData | date: 'yyyy-MM-dd H:mm'}}</small><br>
                <small><i data-feather="server"></i> {{watch.lastSyncServe | date: 'yyyy-MM-dd H:mm'}}</small><br>
                <small><i data-feather="clock"></i> {{watch.lastSync | date:'yyyy-MM-dd HH:mm'}}</small>
              </div>
              <div class="col-md-1">
                <small><i data-feather="battery" class="font-medium-3"></i> {{watch.batteryLevel}}%</small>
              </div>
            </div>
          </div>

          <ng-template #noWatch>
            No Device
          </ng-template>

        </div>
        <hr>
      </li>
    </ul>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-info" (click)="modal.close('Accept click')" rippleEffect>
      Accept
    </button>
  </div>
</ng-template>
<!-- / Modal -->


<!-- Modal Info chart Sleep-->
<ng-template #modalInfoDataSleep let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="myModalLabel160">Detalle del periodo de sueño</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <!-- Apexchart Line Chart Component-->
    <section id="apex-line-chart">
          <div class="row">
            <div class="col-md-10">
              <div class="col-12">
                <!-- / Sleep Chart -->

                <div class="card">
                  <div
                    class="card-header d-flex flex-sm-row flex-column justify-content-md-between align-items-start justify-content-start">
                    <div>
                      <h4 class="card-title">Sleep Chart</h4>
                      <span class="card-subtitle text-muted">Periodo de sueño del usuario</span>
                    </div>
                  </div>
                  <div class="card-body">
                    <div id="apexLineChartId" #apexLineAreaChartRef>
                      <apx-chart
                        [series]="apexLineAreaChart.series" 
                        [chart]="apexLineAreaChart.chart"
                        [xaxis]="apexLineAreaChart.xaxis"
                        [yaxis]="apexLineAreaChart.yaxis"
                        [tooltip]="apexLineAreaChart.tooltip"
                        [plotOptions]="apexLineAreaChart.plotOptions"
                        [dataLabels]="apexLineAreaChart.dataLabels">
                      </apx-chart>
                    </div>
                  </div>
                </div>
  
              </div>
              <div class="col-12">

                <div class="card">
                  <div
                    class="card-header d-flex flex-sm-row flex-column justify-content-md-between align-items-start justify-content-start">
                    <div>
                       <span class="card-subtitle text-muted">Periodos de sueño</span>
                    </div>
                  </div>
                  <div class="card-body">
                      <!-- / Sleep Bloks -->
                      <div class="d-flex ">
                        <div *ngFor="let sleep of blocks">
                          <div class="col-lg-3 col-sm-6 col-12">
                            <div class="card" style="background :#f7f7f7; width: 300px;">
                              <div class="card-header">
                                <div>
                                  <p class="card-text"><img src="assets/images/sleeping.png" alt="" width="30"> {{sleep.start | date:'MMM d, h:mm:ss a'}} </p>
                                  <p class="card-text"><img src="assets/images/wake-up.png" alt="" width="30">  {{sleep.end | date:'MMM d, h:mm:ss a' }}</p>
                                </div>
                                <div class="p-50 m-0">
                                  <div class="avatar-content">
                                    <img src="assets/images/sleeping_moon.png" alt="" width="50">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
 
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">

              <div class="card">
                <div
                  class="card-header d-flex flex-sm-row flex-column justify-content-md-between align-items-start justify-content-start">
                  <div>
                     <span class="card-subtitle text-muted">Clasificacion de Riesgo</span>
                  </div>
                </div>
                <div class="card-body">
                  <!-- / Moon Img -->
                  <div class="text-center">
                    <div class="text-center">
                      <img src="assets/images/moons/{{moon}}" width="40" />
                    </div>
                    <p class="text-center">{{risk}}</p>
                    <h1 class="text-center">{{totalTTS}}</h1>
                  </div>
    
                </div>
              </div>
              
              <div class="card">
                <div
                  class="card-header d-flex flex-sm-row flex-column justify-content-md-between align-items-start justify-content-start">
                  <div>
                     <span class="card-subtitle text-muted">Eficiencia</span>
                  </div>
                </div>
                <div class="card-body">
                  <div id="chart">
                    <apx-chart
                      [series]="chartOptionPie.series"
                      [chart]="chartOptionPie.chart"
                      [labels]="chartOptionPie.labels"
                      [responsive]="chartOptionPie.responsive"
                      [legend]="chartOptionPie.legend"
                      [colors]="chartOptionPie.colors"
                     ></apx-chart>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </section>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-info" (click)="modal.close('Accept click')" rippleEffect>
      Accept
    </button>
  </div>
</ng-template>
<!-- / Modal -->