import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { collection, query } from 'firebase/firestore';
 
 

@Injectable()
export class DatatablesService implements Resolve<any> {
  rows: any;
  rowsAll: any[];
  onDatatablessChanged: BehaviorSubject<any>;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private db: AngularFireDatabase) {
    // Set the defaults
    this.onDatatablessChanged = new BehaviorSubject({});
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getDataTableRowsJoin()]).then(() => {
        resolve();
      }, reject);
    });
  }

  /**
   * Get rows
   */

  /*
  getDataTableRows(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get('https://isleeplab-default-rtdb.firebaseio.com/device.json').subscribe((response: any) => {
        this.rows = response;
        this.onDatatablessChanged.next(this.rows);
        resolve(this.rows);
      } , reject);
    });
  }
  */

  getDataTableRows(): Promise<any[]> {
    this.rowsAll = []
    return new Promise((resolve, reject) => {
      const  device = this.db.list('device')
  

      device.snapshotChanges().subscribe(data => {
        data.forEach(item =>{
          //console.log(item.payload.val()["-DeviceDetails"])
          // item.payload.val()["-DeviceDetails"]
          this.rowsAll.push(item.payload.val()["-DeviceDetails"])
        })
        //console.log(this.rowsAll)
         this.rows = this.rowsAll
         this.onDatatablessChanged.next(this.rows);
         resolve(this.rows);
      }, reject);
    });
  }

  
  getDataTableRowsJoin(): Promise<any[]> {
    this.rowsAll = []
    return new Promise((resolve, reject) => {
      const  deviceRef = this.db.list('device')
       
      deviceRef.valueChanges().pipe
      deviceRef.snapshotChanges().subscribe(data => {
        this.rowsAll.length = 0
        data.forEach(item =>{
            const device :  any = item.payload.val()["-DeviceDetails"]
            const  watch = this.db.list('watch/'+item.key)
            
            watch.snapshotChanges().subscribe( data => {
              const watchData: any[] = []
              data.forEach( value => {
                const watch :any = value.payload.val()
                watchData.push(watch)
                device.watchs = watchData
               
              })
              
               
            })
            this.rowsAll.push(device)
        })
        
         this.rows = this.rowsAll
         
         this.onDatatablessChanged.next(this.rows);
         resolve(this.rows);
      }, reject);
    });
  }

  

}
