<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- Collapse start -->
    <section id="collapsible">
      <div class="row">
        <div class="col-sm-12">
          <core-card-snippet [snippetCode]="_snippetCodeCollapset">
            <h4 class="card-title">Collapse</h4>
            <div class="card-body collapse-icon">
              <p class="card-text">With basic collapse you can open multiple items at a time.</p>

              <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
                <ngb-panel>
                  <ng-template ngbPanelTitle>
                    <span class="lead collapse-title"> Collapse Item 1 </span>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    Pie dragée muffin. Donut cake liquorice marzipan carrot cake topping powder candy. Sugar plum
                    brownie brownie cotton candy. Tootsie roll cotton candy pudding bonbon chocolate cake lemon drops
                    candy. Jelly marshmallow chocolate cake carrot cake bear claw ice cream chocolate. Fruitcake apple
                    pie pudding jelly beans pie candy canes candy canes jelly-o. Tiramisu brownie gummi bears soufflé
                    dessert cake.
                  </ng-template>
                </ngb-panel>
                <ngb-panel>
                  <ng-template ngbPanelTitle>
                    <span class="lead collapse-title"> Collapse Item 2 </span>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    Pie dragée muffin. Donut cake liquorice marzipan carrot cake topping powder candy. Sugar plum
                    brownie brownie cotton candy. Tootsie roll cotton candy pudding bonbon chocolate cake lemon drops
                    candy. Jelly marshmallow chocolate cake carrot cake bear claw ice cream chocolate. Fruitcake apple
                    pie pudding jelly beans pie candy canes candy canes jelly-o. Tiramisu brownie gummi bears soufflé
                    dessert cake.
                  </ng-template>
                </ngb-panel>
                <ngb-panel>
                  <ng-template ngbPanelTitle>
                    <span class="lead collapse-title"> Collapse Item 3 </span>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    Pie dragée muffin. Donut cake liquorice marzipan carrot cake topping powder candy. Sugar plum
                    brownie brownie cotton candy. Tootsie roll cotton candy pudding bonbon chocolate cake lemon drops
                    candy. Jelly marshmallow chocolate cake carrot cake bear claw ice cream chocolate. Fruitcake apple
                    pie pudding jelly beans pie candy canes candy canes jelly-o. Tiramisu brownie gummi bears soufflé
                    dessert cake.
                  </ng-template>
                </ngb-panel>
                <ngb-panel [disabled]="true">
                  <ng-template ngbPanelTitle>
                    <span class="lead collapse-title"> Collapse Item 4 (Disabled) </span>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    Pie dragée muffin. Donut cake liquorice marzipan carrot cake topping powder candy. Sugar plum
                    brownie brownie cotton candy. Tootsie roll cotton candy pudding bonbon chocolate cake lemon drops
                    candy. Jelly marshmallow chocolate cake carrot cake bear claw ice cream chocolate. Fruitcake apple
                    pie pudding jelly beans pie candy canes candy canes jelly-o. Tiramisu brownie gummi bears soufflé
                    dessert cake.
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
          </core-card-snippet>
        </div>
      </div>
    </section>
    <!-- Collapse end -->

    <!-- Accordion start -->
    <section id="collapsible">
      <div class="row">
        <div class="col-sm-12">
          <core-card-snippet [snippetCode]="_snippetCodeAccordion">
            <h4 class="card-title">Accordion</h4>
            <div class="card-body collapse-icon">
              <p class="card-text">You may want to open one item at a time, for that you can use accordion.</p>

              <ngb-accordion [closeOthers]="true" activeIds="accordion-1">
                <ngb-panel id="accordion-1">
                  <ng-template ngbPanelTitle>
                    <span class="lead collapse-title"> Accordion Item 1 </span>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    Pie dragée muffin. Donut cake liquorice marzipan carrot cake topping powder candy. Sugar plum
                    brownie brownie cotton candy. Tootsie roll cotton candy pudding bonbon chocolate cake lemon drops
                    candy. Jelly marshmallow chocolate cake carrot cake bear claw ice cream chocolate. Fruitcake apple
                    pie pudding jelly beans pie candy canes candy canes jelly-o. Tiramisu brownie gummi bears soufflé
                    dessert cake.
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="accordion-2">
                  <ng-template ngbPanelTitle>
                    <span class="lead collapse-title"> Accordion Item 2 </span>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    Pie dragée muffin. Donut cake liquorice marzipan carrot cake topping powder candy. Sugar plum
                    brownie brownie cotton candy. Tootsie roll cotton candy pudding bonbon chocolate cake lemon drops
                    candy. Jelly marshmallow chocolate cake carrot cake bear claw ice cream chocolate. Fruitcake apple
                    pie pudding jelly beans pie candy canes candy canes jelly-o. Tiramisu brownie gummi bears soufflé
                    dessert cake.
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="accordion-3">
                  <ng-template ngbPanelTitle>
                    <span class="lead collapse-title"> Accordion Item 3 </span>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    Pie dragée muffin. Donut cake liquorice marzipan carrot cake topping powder candy. Sugar plum
                    brownie brownie cotton candy. Tootsie roll cotton candy pudding bonbon chocolate cake lemon drops
                    candy. Jelly marshmallow chocolate cake carrot cake bear claw ice cream chocolate. Fruitcake apple
                    pie pudding jelly beans pie candy canes candy canes jelly-o. Tiramisu brownie gummi bears soufflé
                    dessert cake.
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="accordion-4" [disabled]="true">
                  <ng-template ngbPanelTitle>
                    <span class="lead collapse-title"> Accordion Item 4 (Disabled) </span>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    Pie dragée muffin. Donut cake liquorice marzipan carrot cake topping powder candy. Sugar plum
                    brownie brownie cotton candy. Tootsie roll cotton candy pudding bonbon chocolate cake lemon drops
                    candy. Jelly marshmallow chocolate cake carrot cake bear claw ice cream chocolate. Fruitcake apple
                    pie pudding jelly beans pie candy canes candy canes jelly-o. Tiramisu brownie gummi bears soufflé
                    dessert cake.
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
          </core-card-snippet>
        </div>
      </div>
    </section>
    <!-- Collapse end -->

    <!-- Collapse button start -->
    <section id="collapsible">
      <div class="row">
        <div class="col-sm-12">
          <core-card-snippet [snippetCode]="_snippetCodeButtonCollapse" class="">
            <h4 class="card-title">Button Collapse</h4>
            <div class="card-body">
              <p class="card-text">
                Use <code>[ngbCollapse]</code> directive to show/hide the content with it. If true, will collapse the
                element or show it otherwise. Default value is <strong>false</strong>.
              </p>
              <button
                type="button"
                class="btn btn-outline-primary"
                (click)="isCollapsed5 = !isCollapsed5"
                [attr.aria-expanded]="!isCollapsed5"
                aria-controls="collapseExample4"
                rippleEffect
              >
                Toggle
              </button>
              <div [ngbCollapse]="isCollapsed5">
                <div class="card mb-0">
                  <div class="card-body">
                    Pie drawee muffin. Donut cake liquorice marzipan carrot cake topping powder candy. Sugar plum
                    brownie brownie cotton candy. Tootsie roll cotton candy pudding bonbon chocolate cake lemon drops
                    candy. Jelly marshmallow chocolate cake carrot cake bear claw ice cream chocolate. Fruitcake apple
                    pie pudding jelly beans pie candy canes candy canes jelly-o. Tiramisu brownie gummi bears soufflé
                    dessert cake.
                  </div>
                </div>
              </div>
            </div>
          </core-card-snippet>
        </div>
      </div>
    </section>
    <!-- Collapse button end -->
  </div>
</div>
