<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <!-- content-header component -->
      <app-content-header [contentHeader]="contentHeader"></app-content-header>
  
      <!-- Kitchen Sink Datatables -->
      <section id="ngx-datatable-kitchen-sink">

        <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Usuarios Vinculados</h4>
                </div>
                <div class="card-body pb-1">
                

          <div class="row">
            <div class="col-12 mb-50">
              <button class="btn btn-primary ml-1" rippleEffect>
                <i data-feather="plus" class="mr-0 mr-sm-1"></i
                ><span class="d-none d-sm-inline-block">Añadir nuevo usuario</span>
              </button>
  
              <a csvLink [data]="exportCSVData" class="btn btn-outline-secondary float-right mr-1" rippleEffect
                >Export CSV</a
              >
            </div>
            <div class="col-md-6 col-12">
              <div class="d-flex justify-content-between align-items-center m-1">
                <label class="d-flex align-items-center"
                  >Show
                  <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  entries</label
                >
              </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
              <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                <label class="d-flex align-items-center"
                  >Search<input
                    type="search"
                    placeholder="Search"
                    class="form-control ml-25"
                    (keyup)="filterUpdate($event)"
                /></label>
              </div>
            </div>
          </div>
          <ngx-datatable
            [rows]="users"
            [rowHeight]="58"
            class="bootstrap core-bootstrap"
            [limit]="10"
            [columnMode]="ColumnMode.force"
            [headerHeight]="40"
            [footerHeight]="50"
            [scrollbarH]="true"
            [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"
            (activate)="onActivate($event)"
            (select)="onSelect($event)"
          >
            <ngx-datatable-column
              [width]="50"
              [sortable]="false"
              [canAutoResize]="false"
              [draggable]="false"
              [resizeable]="false"
            >
              <ng-template
                ngx-datatable-header-template
                let-value="value"
                let-allRowsSelected="allRowsSelected"
                let-selectFn="selectFn"
              >
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    [checked]="allRowsSelected"
                    (change)="selectFn(!allRowsSelected)"
                    id="headerChkbxRef"
                  />
                  <label class="custom-control-label" for="headerChkbxRef"></label>
                </div>
              </ng-template>
              <ng-template
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-value="value"
                let-isSelected="isSelected"
                let-onCheckboxChangeFn="onCheckboxChangeFn"
              >
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    [checked]="isSelected"
                    (change)="onCheckboxChangeFn($event)"
                    id="rowChkbxRef{{ rowIndex }}"
                  />
                  <label class="custom-control-label" for="rowChkbxRef{{ rowIndex }}"></label>
                </div>
              </ng-template>
            </ngx-datatable-column>

          
            <ngx-datatable-column name="Nombre" prop="name" [width]="80"></ngx-datatable-column>
            <ngx-datatable-column name="Email" prop="email" [width]="80"></ngx-datatable-column>
            <ngx-datatable-column name="Empresa" prop="business.name" [width]="120"></ngx-datatable-column>
         
            <ngx-datatable-column name="Device" prop="device" [width]="180">
              <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                <div class="d-flex align-items-center">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="media">
                        <div class="avatar bg-light-success rounded">
                          <span class="avatar-content" style="height: 45px;">
                            <i data-feather="watch" class="avatar-icon font-medium-3"></i>
                          </span>
                        </div>
                        <div class="media-body">
                          <small class="font-weight-bolder">{{row.device.mac}}</small><br>
                          <small class="mb-0">{{row.device.auth_key}}</small><br>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngx-datatable-column>
        
            <ngx-datatable-column name="Actions" [width]="120" [sortable]="false">
              <ng-template ngx-datatable-cell-template>
                <div class="d-flex align-items-center">
                  <div ngbDropdown container="body">
                    <a
                      ngbDropdownToggle
                      href="javascript:void(0);"
                      class="hide-arrow"
                      id="dropdownBrowserState"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                    </a>
                    <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownBrowserState">
                      <a href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center"
                        ><i data-feather="file-text" class="mr-50"></i> Details</a
                      >
                      <a href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center"
                        ><i data-feather="archive" class="mr-50"></i> Archive</a
                      >
                      <a href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center"
                        ><i data-feather="trash-2" class="mr-50"></i> Delete</a
                      >
                    </div>
                  </div>
  
                  <i data-feather="edit" class="text-primary cursor-pointer"></i>
                </div>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
     
                </div>
              </div>
            </div>
        </div>
          
        </section>
      <!--/ Kitchen Sink Datatables -->
  
    </div>
  </div>
  