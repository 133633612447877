import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CoreCommonModule } from '@core/common.module';

import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

import { SampleComponent } from './sample.component';
import { HomeComponent } from './home.component';
import { AuthGuard } from 'app/services/guard/auth.guard';
import { DatatablesService } from 'app/services/datatables.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UsersComponent } from '../admin/users/users.component';
const routes = [
  {
    path: 'sample',
    component: SampleComponent,
    data: { animation: 'sample' }
  },
  {
    path: 'home',
    component: HomeComponent, canActivate : [AuthGuard],
    resolve:{
      datatables: DatatablesService
    },
    data: { animation: 'home' },
  }
];

@NgModule({
  declarations: [SampleComponent, HomeComponent],
  imports: [
    RouterModule.forChild(routes), 
    ContentHeaderModule, 
    TranslateModule, 
    CoreCommonModule,
    CardSnippetModule,
    NgxDatatableModule,
    NgbModule],
  exports: [SampleComponent, HomeComponent]
})
export class SampleModule {}
