import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { coreConfig } from 'app/app-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { provideDatabase,getDatabase } from '@angular/fire/database';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';
import { AuthService } from './services/auth.service';
import { AngularFireModule } from '@angular/fire/compat';
import { CollapseModule } from 'app/main/components/collapse/collapse.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DatatablesService } from 'app/services/datatables.service';
import { AdminModule } from './main/admin/admin.module';
import { FormsModule } from '@angular/forms';
import { CustomersComponent } from './main/isleep/customers/customers.component';
import { MainModule } from './main/main.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { CsvModule } from '@ctrl/ngx-csv';
import { CommonModule } from '@angular/common';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { NgApexchartsModule } from 'ng-apexcharts';
import { LogV2Component } from './log-v2/log-v2.component';
import { LogDetailV2Component } from './log-detail-v2/log-detail-v2.component';
import { AuthGuard } from './services/guard/auth.guard';

const appRoutes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule),
  },
  {
    path: '',
    redirectTo: '/isleep/customers', // defaul url ''
    pathMatch: 'full'
  },
  {
    path: 'isleep',
    loadChildren: () => import('./main/main.module').then(m => m.MainModule),
   },
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
  }
];

 

@NgModule({
  declarations: [AppComponent, CustomersComponent, LogV2Component, LogDetailV2Component],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy'
    }),
    TranslateModule.forRoot(),
    CommonModule,
    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    // App modules
    LayoutModule,
    SampleModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideDatabase(() => getDatabase()),
    provideFirestore(() => getFirestore()),
    AngularFireModule.initializeApp(environment.firebase),

    CollapseModule,
    NgxDatatableModule,
    FormsModule,
    NgSelectModule,
    CsvModule,
    AdminModule,
    NgApexchartsModule,
    Ng2FlatpickrModule
  ],
  providers:[AuthService,DatatablesService],
  bootstrap: [AppComponent]
})
export class AppModule {}
