export const locale = {
  lang: 'fr',
  data: {
    MENU: {
      LOG: 'Log',
      COMPANY: 'Empresaas',
      USERS: 'Usuarios'
    }
  }
}
