import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { User } from 'app/model/User';
import firebase from 'firebase/compat/app';
import { BehaviorSubject, Observable, pipe } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  private currentUserSubject: BehaviorSubject<User>;
   //public
  public currentUser: Observable<User>;

  constructor(
    private auth : AngularFireAuth,
    public router: Router,
    public ngZone: NgZone
    ) {

 
      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
      this.currentUser = this.currentUserSubject.asObservable();

     }

      // getter: currentUserValue
      public get currentUserValue(): User {
        return this.currentUserSubject.value;
      }

      /*
     SingUp(email, password){
       return this.auth.createUserWithEmailAndPassword(email,password)
       .then(
         (result) => {
          this.SetUserData(result.user);
         }
       )

     }
     */

     SingIn(email, password){
      return this.auth.signInWithEmailAndPassword(email,password)
      .then((result) => {
        this.SetUserData(result.user);
        this.currentUserSubject.next(result.user);
        this.ngZone.run(() => {
          this.router.navigate(['']); // '' defualt rediret url /-> app.module config
        })
        return result.user
      }).catch((error) => {
        //window.alert(error.message)
        return error
      })

    }

    

    logout(){
       return this.auth.signOut().then(()=>{
         // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        // notify
        this.currentUserSubject.next(null);
       })
     }


     SetUserData(user) {
      localStorage.setItem('currentUser', JSON.stringify(user));
    }



    get isLoggedIn(): boolean {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return (user !== null) ? true : false;
    }

     
    get getUser(): User{
      return this.currentUserSubject.value;
    }

}
