import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {User} from '../../../services/response/User';
import {ResponseUser} from 'app/services/response/ResponseUser';
import {ColumnMode, DatatableComponent, SelectionType} from '@swimlane/ngx-datatable';
import {UserServiceService} from 'app/services/user-service.service';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UsersComponent implements OnInit {


    private _unsubscribeAll: Subject<any>;
    private tempData = [];

    // public
    public contentHeader: object;
    public rows: User[];
    public selected = [];
    public basicSelectedOption = 10;
    public ColumnMode = ColumnMode;
    public expanded = {};
    public editingName = {};
    public editingStatus = {};
    public editingAge = {};
    public editingSalary = {};
    public chkBoxSelected = [];
    public SelectionType = SelectionType;
    public exportCSVData = [];

    @ViewChild(DatatableComponent) table: DatatableComponent;
    @ViewChild('tableRowDetails') tableRowDetails: any;

    users: User[] = [];
    destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(private serviceUser: UserServiceService) {
        this._unsubscribeAll = new Subject();
    }


    ngOnInit(): void {
        this.serviceUser.onDatatablessChanged.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(response => {
                this.rows = response;
                this.tempData = this.rows;
                this.users = this.rows;
                this.exportCSVData = this.rows;
            });

    }

    filterUpdate(event) {
        const val = event.target.value.toLowerCase();

        // filter our data
        const temp = this.tempData.filter(function (d) {
            return d.full_name.toLowerCase().indexOf(val) !== -1 || !val;
        });

        // update the rows
        this.users = temp;
        // Whenever the filter changes, always go back to the first page
        this.table.offset = 0;
    }

    onActivate(event) {
        // console.log('Activate Event', event);
    }

    onSelect({selected}) {
        console.log('Select Event', selected, this.selected);

        this.selected.splice(0, this.selected.length);
        this.selected.push(selected);
    }

    // tslint:disable-next-line:use-life-cycle-interface
    ngOnDestroy() {
        this.destroy$.next(true);
        // Unsubscribe from the subject
        this.destroy$.unsubscribe();
    }


}
