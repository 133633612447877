import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { environment } from 'environments/environment';
import {  BehaviorSubject, Observable, throwError } from 'rxjs';
 import { ResponseDevice } from './response/ResponseDevice';
 
@Injectable({
  providedIn: 'root'
})
export class DeviceServiceService implements Resolve<any>{

  private API_URL= environment.apiUrl;
  
  rows: any;
  onDatatablessChanged: BehaviorSubject<any>;
  
  constructor(private http: HttpClient) { 
    this.onDatatablessChanged = new BehaviorSubject({});
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getDevices()]).then(() => {
        resolve();
      }, reject);
    });
  }

  public getDevices(): Promise<any[]>{
    return new Promise((resolve,reject)=>{
      this.http.get(this.API_URL+'/device')
      .subscribe((response:ResponseDevice)=>{
        this.rows = response.data.devices;
        this.onDatatablessChanged.next(this.rows)
        resolve(this.rows);
      },reject)
    })
  }

}
