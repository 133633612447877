<div class="content-wrapper container-xxl p-0">
    <div class="content-body">


        <!-- Kitchen Sink Datatables -->
        <section id="ngx-datatable-kitchen-sink">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Device with log DATA 🚀</h4>
                        </div>
                        <div class="card-body pb-1">

                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <div class="d-flex justify-content-between align-items-center m-1">
                                        <label class="d-flex align-items-center">Show
                                            <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                            entries
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-12 d-flex justify-content-md-end">
                                    <div
                                        class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                                        <div class="d-flex">
                                            <div class="custom-control custom-radio">
                                                <input type="radio" id="customRadio1" name="customRadio"
                                                    class="custom-control-input" [(ngModel)]="filter" value="1" checked
                                                    (change)="onItemChange($event.target.value)" />
                                                <label class="custom-control-label" for="customRadio1">Token</label>
                                            </div>
                                            <div class="custom-control custom-radio">
                                                <input type="radio" id="customRadio2" name="customRadio"
                                                    class="custom-control-input" [(ngModel)]="filter" value="2"
                                                    (change)="onItemChange($event.target.value)" />
                                                <label class="custom-control-label" for="customRadio2">Email</label>
                                            </div>
                                            <div class="custom-control custom-radio">
                                                <input type="radio" id="customRadio3" name="customRadio"
                                                    class="custom-control-input" [(ngModel)]="filter" value="3"
                                                    (change)="onItemChange($event.target.value)" />
                                                <label class="custom-control-label" for="customRadio3">Mac</label>
                                            </div>
                                        </div>
                                        <label class="d-flex align-items-center"><input type="search"
                                                placeholder="Buscar" class="form-control ml-25"
                                                (keyup)="filterUpdate($event)" /></label>

                                    </div>
                                </div>
                            </div>



                            <ngx-datatable #tableRowDetails [rows]="kitchenSinkRows" [rowHeight]="65"
                                class="bootstrap core-bootstrap material" [limit]="100"
                                [columnMode]="ColumnMode.standard" [headerHeight]="40" [footerHeight]="50"
                                [scrollbarH]="true" [limit]="basicSelectedOption">

                                <ngx-datatable-column name="Device/UID" prop="token" [width]="200">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        <div class="d-flex align-items-center">
                                            <div class="cell-line-height">
                                                <p class="font-medium-1 font-weight-bold line-height-1 mb-25">
                                                    {{ row.brand }}
                                                </p>
                                                <span class="text-muted font-small-1" style="font-size: 9px !important">
                                                    {{row.UID}}</span>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>

                                <ngx-datatable-column name="Device" prop="token" [width]="150">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        <div class="d-flex align-items-center">
                                            <div *ngIf="row.type == 'Tablet' else phone">
                                                <div class="media">
                                                    <div class="avatar bg-light-primary rounded">
                                                        <div class="avatar-content">
                                                            <small>TAB</small>
                                                        </div>
                                                    </div>
                                                    <div class="media-body">
                                                        <h6 class="transaction-title"> {{ row.manufacturer }}</h6>
                                                        <small>{{row.model}} </small>
                                                    </div>
                                                </div>
                                            </div>
                                            <ng-template #phone>
                                                <div class="media">
                                                    <div class="avatar bg-light-primary rounded">
                                                        <div class="avatar-content">
                                                            <i data-feather="smartphone"
                                                                class="avatar-icon font-medium-3"></i>
                                                        </div>
                                                    </div>
                                                    <div class="media-body">
                                                        <h6 class="transaction-title"> {{ row.manufacturer }}</h6>
                                                        <small>{{row.model}} </small>
                                                    </div>
                                                </div>
                                            </ng-template>

                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>

                                <ngx-datatable-column name="APP" prop="token" [width]="150">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        <div class="d-flex align-items-center">
                                            <div class="media">
                                                <div class="avatar bg-light-success rounded">
                                                    <div class="avatar-content">
                                                        APP
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Info APP</h6>
                                                    <small>v: {{row.appVersionName}} | OS : {{row.osVersion}}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>

                                <ngx-datatable-column name="Watch" prop="watchs" [width]="150">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                       <button type="button" class="btn btn-outline-info" (click)="modalInfoDevicesAll(modalInfo,row)"
                                        rippleEffect>
                                        <i data-feather="watch" class="avatar-icon font-medium-3"></i> Watchs
                                       </button>
                                    </ng-template>
                                  </ngx-datatable-column>
                               
                                  <ngx-datatable-column name="email">
                                  </ngx-datatable-column>


                        <ngx-datatable-column name="Remote Log">
                            <ng-template let-row="row" let-name="value" let-isSelected="isSelected"
                                ngx-datatable-cell-template>
                                <div class="custom-control custom-control-success custom-switch">
                                    <input type="checkbox" class="custom-control-input" [id]="row.token"
                                        [checked]="(row.remoteLog == 'true')" (change)="onCheckboxChangeFn(row)"
                                        [name]="row.token" />
                                    <label class="custom-control-label" [for]="row.token"></label>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>

                        </ngx-datatable>

                    </div>
                </div>
            </div>
    </div>

    </section>
    <!--/ Kitchen Sink Datatables -->

</div>
</div>


<!-- Modal Info waches-->
<ng-template #modalInfo let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="myModalLabel160">Dispositivos viculados a ala TABLET</h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>

        <ul>
            <li *ngFor="let watch of rowselect">


                <div class="align-items-center">
                    <div *ngIf="watch; else noWatch">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="media">
                                    <div class="avatar bg-light-warning rounded">
                                        <span class="avatar-content" style="height: 45px;">
                                            <i data-feather="watch" class="avatar-icon font-medium-3"></i>
                                        </span>
                                        <div>
                                            <span class="avatar-status-online"></span>
                                        </div>
                                    </div>

                                    <div class="media-body">
                                        <small class="font-weight-bolder">{{watch.key}}</small><br>
                                        <small class="mb-0">--</small><br>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1">
                              
                                    <a target="_blank" href="/log-v2/detail/{{ watch.key }}/user/{{watch.UID}}" class="btn btn-outline-success round"
                                        rippleEffect><i data-feather="file-text"></i> </a>
                               
                            </div>
                        </div>
                    </div>

                    <ng-template #noWatch>
                        No Device
                    </ng-template>

                </div>
                <hr>
            </li>
        </ul>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-info" (click)="modal.close('Accept click')" rippleEffect>
            Accept
        </button>
    </div>
</ng-template>
<!-- / Modal -->