<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- Kitchen Sink Datatables -->
    <section id="ngx-datatable-kitchen-sink">
      <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Dispositivos activados</h4>
              </div>
              <div class="card-body pb-1">
              

              <div class="row">
                <div class="col-12 mb-50">
                  <button type="button" (click)="modalOpenForm(modalForm)" rippleEffect class="btn btn-primary ml-1" rippleEffect>
                    <i data-feather="plus" class="mr-0 mr-sm-1"></i
                    ><span class="d-none d-sm-inline-block">Vincular nuevo Reloj</span>
                  </button>

                  <a csvLink [data]="exportCSVData" class="btn btn-outline-secondary float-right mr-1" rippleEffect
                    >Export CSV</a
                  >
                </div>
                <div class="col-md-6 col-12">
                  <div class="d-flex justify-content-between align-items-center m-1">
                    <label class="d-flex align-items-center"
                      >Show
                      <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      entries</label
                    >
                  </div>
                </div>
                <div class="col-md-6 col-12 d-flex justify-content-md-end">
                  <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center"
                      >Search<input
                        type="search"
                        placeholder="Search"
                        class="form-control ml-25"
                        (keyup)="filterUpdate($event)"
                    /></label>
                  </div>
                </div>
              </div>
              <ngx-datatable
                #table
                [rows]="devices"
                [rowHeight]="58"
                class="bootstrap core-bootstrap"
                [limit]="10"
                [columnMode]="ColumnMode.force"
                [headerHeight]="40"
                [footerHeight]="50"
                [scrollbarH]="true"
                [selectionType]="SelectionType.checkbox"
                [limit]="basicSelectedOption"
                (select)="onSelect($event)"
              >
                <ngx-datatable-column
                  [width]="50"
                  [sortable]="false"
                  [canAutoResize]="false"
                  [draggable]="false"
                  [resizeable]="false"
                >
                  <ng-template
                    ngx-datatable-header-template
                    let-value="value"
                    let-allRowsSelected="allRowsSelected"
                    let-selectFn="selectFn"
                  >
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        [checked]="allRowsSelected"
                        (change)="selectFn(!allRowsSelected)"
                        id="headerChkbxRef"
                      />
                      <label class="custom-control-label" for="headerChkbxRef"></label>
                    </div>
                  </ng-template>
                  <ng-template
                    ngx-datatable-cell-template
                    let-rowIndex="rowIndex"
                    let-value="value"
                    let-isSelected="isSelected"
                    let-onCheckboxChangeFn="onCheckboxChangeFn"
                  >
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        [checked]="isSelected"
                        (change)="onCheckboxChangeFn($event)"
                        id="rowChkbxRef{{ rowIndex }}"
                      />
                      <label class="custom-control-label" for="rowChkbxRef{{ rowIndex }}"></label>
                    </div>
                  </ng-template>
                </ngx-datatable-column>

              
                <ngx-datatable-column name="Address" prop="mac" [width]="120"></ngx-datatable-column>
                <ngx-datatable-column name="Token" prop="auth_key" [width]="120"></ngx-datatable-column>
                <ngx-datatable-column name="Status" prop="active" [width]="120"></ngx-datatable-column>
                <ngx-datatable-column name="Created at" prop="created_date" [width]="120"></ngx-datatable-column>
            
                <ngx-datatable-column name="Actions" [width]="120" [sortable]="false">
                  <ng-template ngx-datatable-cell-template>
                    <div class="d-flex align-items-center">
                      <div ngbDropdown container="body">
                        <a
                          ngbDropdownToggle
                          href="javascript:void(0);"
                          class="hide-arrow"
                          id="dropdownBrowserState"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                        </a>
                        <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownBrowserState">
                          <a href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center"
                            ><i data-feather="file-text" class="mr-50"></i> Details</a
                          >
                          <a href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center"
                            ><i data-feather="archive" class="mr-50"></i> Archive</a
                          >
                          <a href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center"
                            ><i data-feather="trash-2" class="mr-50"></i> Delete</a
                          >
                        </div>
                      </div>

                      <i data-feather="edit" class="text-primary cursor-pointer"></i>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
              </ngx-datatable>
   
              </div>
            </div>
          </div>
      </div>
        
      </section>
    <!--/ Kitchen Sink Datatables -->

  </div>
</div>

 <!-- Modal -->
 <ng-template #modalForm let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">Activar nuevo Dispositivo</h4>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>

    
  <div class="nav-vertical">
    <ul ngbNav #navVertical="ngbNav" class="nav nav-tabs nav-left flex-column">
      <li ngbNavItem>
        <a ngbNavLink>Manual</a>
        <ng-template ngbNavContent>
          <p>Registro de Dispositivos</p>
          <div>
            <form action="#">
              <label>Mac : </label>
              <div class="form-group">
                <input type="text" placeholder="Address" 
                class="form-control" [(ngModel)]="Device.mac" name="address"/>
              </div>
              <label>Token : </label>
              <div class="form-group">
                <input type="string" placeholder="Token" 
                class="form-control" [(ngModel)]="Device.token" name="token" />
              </div>
              <label>Numero Caja : </label>
              <div class="form-group">
                <input type="string" placeholder="Numero de caja" 
                class="form-control" [(ngModel)]="Device.number" name="number" />
              </div>
              <label>Serial : </label>
              <div class="form-group">
                <input type="string" placeholder="Serial (Opcional)" 
                class="form-control" [(ngModel)]="Device.serial" name="serial" />
              </div>
              <button class="btn btn-success" (click)="registerDevice()">REGISTAR</button>
            </form>
          </div>
        </ng-template>
      </li>
      <li ngbNavItem>
        <a ngbNavLink>Auth</a>
        <ng-template ngbNavContent>
          <p> Autenticacion de Xiomi (ZEPP LIFE)</p>
          <iframe id="inlineFrameExample"
              title="Inline Frame Example"
              width="450"
              height="400"
              src="https://sleep.innobyte.pe/xiaomi/activate">
          </iframe>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="navVertical"></div>
  </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close('Accept click')" rippleEffect>
      Cancelar
    </button>
  </div>
</ng-template>
