<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <!-- content-header component -->
      <app-content-header [contentHeader]="contentHeader"></app-content-header>
  
      <!-- Kitchen Sink Datatables -->
      <section id="ngx-datatable-kitchen-sink">
    
        <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Lista de Empresas </h4>
                </div>
                <div class="card-body pb-1">
                

                    <!-- Login Form -->
  <div class="login-form">
    <!-- Button trigger modal -->
    <button type="button" (click)="modalOpenForm(modalForm)" class="btn btn-outline-primary" rippleEffect>
      <i data-feather="plus" class="mr-0 mr-sm-1"></i> CREAR
    </button>
  
    <!-- Modal -->
    <ng-template #modalForm let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Datos de la Empresa</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" tabindex="0" ngbAutofocus>
        <form action="#">
          <label>CODIGO : </label>
          <div class="form-group">
            <h1>{{Companies.pairing_code}}</h1>
          </div>
          <label>Nombre : </label>
          <div class="form-group">
            <input type="text" placeholder="Nombre de la empresa " class="form-control" [(ngModel)]="Companies.name" name="name"/>
          </div>
          <div class="form-group">
            <label for="numMonths">Número de meses:</label>
            <input class="form-control" type="number" name="numMonths" [(ngModel)]="numMonths">
          </div>
          <label>Fecha de Inicio (Produccion) :</label>
          <div class="form-group">
            <div class="input-group">
              <input class="form-control" placeholder="dd-mm-yyyy" name="init_date"
                [(ngModel)]="selectedDate" ngbDatepicker #basicDP="ngbDatepicker" (dateSelect)="onDateSelect($event, basicDP)" value="Companies.created_date">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary feather icon-calendar" (click)="basicDP.toggle()"
                  type="button"></button>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="expireDate">Fecha de expiracion:</label>
            <h3>{{Companies.expire_date}}</h3>
          </div>
          <div class="form-group">
            <label for="numMonths">Implementación:</label>
            <div class="row">
              <div class="custom-control custom-control-warning custom-radio">
                <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input" [(ngModel)]="type_deploy" value="1" />
                <label class="custom-control-label" for="customRadio1">Inactivo</label>
              </div>
              <div class="custom-control custom-control-info custom-radio">
                <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input" [(ngModel)]="type_deploy" value="2" />
                <label class="custom-control-label" for="customRadio2">Demo</label>
              </div>
              <div class="custom-control custom-control-success custom-radio">
                <input type="radio" id="customRadio3" name="customRadio" class="custom-control-input" [(ngModel)]="type_deploy" value="3" />
                <label class="custom-control-label" for="customRadio3">Producción</label>
              </div>
            </div>
          </div>
          <hr>
          <div class="form-group">
            <label for="numMonths">Asignar como Sede o Grupo:</label>
            <div class="row">
              <div class="custom-control custom-control-primary custom-radio">
                <input type="radio" id="empresa" name="parent" class="custom-control-input" [(ngModel)]="hasParent" value="1" />
                <label class="custom-control-label" for="empresa">Sede</label>
              </div>
              <div class="custom-control custom-control-primary custom-radio">
                <input type="radio" id="campus" name="parent" class="custom-control-input" [(ngModel)]="hasParent" value="2" checked />
                <label class="custom-control-label" for="campus">Grupo</label>
              </div>
            </div>
          </div>
          
          <div *ngIf="hasParent == 1">
            <div class="form-group">
              <label for="parentEntity">Empresa donde asignara la SEDE :</label>
              <select class="form-control" name="parentEntity" [(ngModel)]="id_campus" (change)="onGroupSelectionChange($event)">
                <option *ngFor="let entity of rows_business" [value]="entity.id">{{ entity.name }}</option>
              </select>
            </div>
          </div>
          
          <div *ngIf="hasParent == 2">
            <div class="form-group">
              <label for="parentEntity">Empresa donde asignara la GRUPO :</label>
              <select class="form-control" name="parentEntity" [(ngModel)]="id_campus" (change)="onGroupSelectionChange($event)">
                <option *ngFor="let entity of rows_business" [value]="entity.id">{{ entity.name }}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="parentEntity">Sede donde asignara la GRUPO:</label>
              <select class="form-control" name="parentEntity" [(ngModel)]="id_group">
                <option *ngFor="let entity of rows_group" [value]="entity.id">{{ entity.name }}</option>
              </select>
            </div>
          </div>
          
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="createBussiness()" rippleEffect>
          Crear
        </button>
      </div>
    </ng-template>
    <!-- / Modal -->
  </div>
  
          <div class="row">
            <div class="col-12 mb-50">
  
              <a csvLink [data]="exportCSVData" class="btn btn-outline-secondary float-right mr-1" rippleEffect
                >Export CSV</a
              >
            </div>
            <div class="col-md-6 col-12">
              <div class="d-flex justify-content-between align-items-center m-1">
                <label class="d-flex align-items-center"
                  >Show
                  <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  entries</label
                >
              </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
              <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                <label class="d-flex align-items-center"
                  >Search<input
                    type="search"
                    placeholder="Search"
                    class="form-control ml-25"
                    (keyup)="filterUpdate($event)"
                /></label>
              </div>
            </div>
          </div>
          <ngx-datatable
            #tableRowDetails
            [rows]="business"
            [rowHeight]="58"
            class="bootstrap core-bootstrap"
            [limit]="10"
            [columnMode]="ColumnMode.force"
            [headerHeight]="40"
            [footerHeight]="50"
            [scrollbarH]="true"
            [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"
           >
           <!-- Row Detail CAMPUS Template -->
           <ngx-datatable-row-detail [rowHeight]="400">
            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
              <div class="ml-75 pl-5 pt-75">
                <div *ngIf="row.campus; else noWatch">
                  <div class="row">
                    <div class="col-md-12">
                         <ngx-datatable
                            #tableRowGroups
                            [rows]="row.campus"
                            [rowHeight]="58"
                            class="bootstrap core-bootstrap"
                            [limit]="10"
                            [columnMode]="ColumnMode.force"
                            [headerHeight]="40"
                            [footerHeight]="50"
                            [scrollbarH]="true"
                            [selectionType]="SelectionType.checkbox"
                            [limit]="basicSelectedOption"
                          >
                          <!-- Row Detail GROUP Template -->
                          <ngx-datatable-row-detail [rowHeight]="400">
                            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                              <div class="ml-75 pl-5 pt-75">
                                <ngx-datatable
                                #tableRowDetails
                                [rows]="row.groups"
                                [rowHeight]="58"
                                class="bootstrap core-bootstrap"
                                [limit]="10"
                                [columnMode]="ColumnMode.force"
                                [headerHeight]="40"
                                [footerHeight]="50"
                                [scrollbarH]="true"
                                [selectionType]="SelectionType.checkbox"
                                [limit]="basicSelectedOption"
                               >  
                                <ngx-datatable-column name="Codigo Emparejamiento" [width]="120">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        <div class="d-flex align-items-center">
                                          <div class="cell-line-height">
                                            <p class="font-medium-5 font-weight-bold line-height-1 mb-25">
                                              {{ row.code_pairing }}
                                            </p>
                                            <span class="text-muted font-small-2"> {{ row.name }}</span>
                                          </div>
                                        </div>
                                      </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Estado" prop="type_deploy" [width]="120">
                                    <ng-template let-status="value" ngx-datatable-cell-template>
                                        <div
                                          class="badge badge-pill"
                                          [ngClass]="{
                                            'badge-light-success': status == '3',
                                            'badge-light-warning': status == '1',
                                            'badge-light-danger': status == '4',
                                            'badge-light-info': status == '2'
                                          }"
                                        >
                                          {{
                                              status == 1
                                              ? 'Inactivo'
                                              : status == 2
                                              ? 'Demo'
                                              : status == 3
                                              ? 'Production'
                                              : 'Expiro'
                                          }}
                                        </div>
                                      </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Fecha de expiracion" prop="date_expire" [width]="120"></ngx-datatable-column>
                                <ngx-datatable-column name="Fecha de Creacion" prop="created_date" [width]="120"></ngx-datatable-column>
                                <ngx-datatable-column name="Actions" [width]="120" [sortable]="false">
                                  <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                    <div class="d-flex align-items-center">
                                      <a (click)="editBusiness(row,modalForm)"> <i data-feather="edit" class="text-primary cursor-pointer" ></i></a>
                                    </div>
                                  </ng-template>
                                </ngx-datatable-column>
                              </ngx-datatable>
                              </div>
                            </ng-template>
                          </ngx-datatable-row-detail>

                            <ngx-datatable-column
                              [width]="50"
                              [resizeable]="false"
                              [sortable]="false"
                              [draggable]="false"
                              [canAutoResize]="false">
                              <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                                <a
                                  href="javascript:void(0)"
                                  [class.datatable-icon-right]="!expanded"
                                  [class.datatable-icon-down]="expanded"
                                  title="Expand/Collapse Row"
                                  (click)="rowDetailsGroupToggleExpand(row)">
                                </a>
                              </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Codigo" [width]="120">
                                <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                    <div class="d-flex align-items-center">
                                      <div class="cell-line-height">
                                        <p class="font-medium-5 font-weight-bold line-height-1 mb-25">
                                          {{ row.code_pairing }}
                                        </p>
                                        <span class="text-muted font-small-2"> {{ row.name }}</span>
                                      </div>
                                    </div>
                                  </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Estado" prop="type_deploy" [width]="120">
                                <ng-template let-status="value" ngx-datatable-cell-template>
                                    <div
                                      class="badge badge-pill"
                                      [ngClass]="{
                                        'badge-light-success': status == '3',
                                        'badge-light-warning': status == '1',
                                        'badge-light-danger': status == '4',
                                        'badge-light-info': status == '2'
                                      }"
                                    >
                                      {{
                                          status == 1
                                          ? 'Inactivo'
                                          : status == 2
                                          ? 'Demo'
                                          : status == 3
                                          ? 'Production'
                                          : 'Expiro'
                                      }}
                                    </div>
                                  </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Fecha de expiracion" prop="date_expire" [width]="120">
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Fecha de Creacion" prop="created_date" [width]="120">
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Grupos" prop="created_date" [width]="120">
                              <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                <div class="d-flex align-items-center">
                                  <div class="cell-line-height">
                                    <p class="font-medium-5 font-weight-bold line-height-1 mb-25">
                                      {{ row.groups.length }}
                                    </p>
                                  </div>
                                </div>
                              </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Actions" [width]="120" [sortable]="false">
                              <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                <div class="d-flex align-items-center">
                                  <a (click)="editBusiness(row,modalForm)"> <i data-feather="edit" class="text-primary cursor-pointer" ></i></a>
                                </div>
                              </ng-template>
                            </ngx-datatable-column>
                          </ngx-datatable>
                    </div>
                  </div>
                 </div>
                
                <ng-template #noWatch>
                  No hay sedes
                </ng-template>
              </div>
            </ng-template>
          </ngx-datatable-row-detail>

            <ngx-datatable-column
              [width]="50"
              [resizeable]="false"
              [sortable]="false"
              [draggable]="false"
              [canAutoResize]="false">
              <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                <a
                  href="javascript:void(0)"
                  [class.datatable-icon-right]="!expanded"
                  [class.datatable-icon-down]="expanded"
                  title="Expand/Collapse Row"
                  (click)="rowDetailsToggleExpand(row)">
                </a>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Codigo " [width]="120">
                <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                    <div class="d-flex align-items-center">
                      <div class="cell-line-height">
                        <p class="font-medium-5 font-weight-bold line-height-1 mb-25">
                          {{ row.code_pairing }}
                        </p>
                        <span class="text-muted font-small-2"> {{ row.name }}</span>
                      </div>
                    </div>
                  </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Estado" prop="type_deploy" [width]="120">
                <ng-template let-status="value" ngx-datatable-cell-template>
                    <div
                      class="badge badge-pill"
                      [ngClass]="{
                        'badge-light-success': status == '3',
                        'badge-light-warning': status == '1',
                        'badge-light-danger': status == '4',
                        'badge-light-info': status == '2'
                      }"
                    >
                      {{
                          status == 1
                          ? 'Inactivo'
                          : status == 2
                          ? 'Demo'
                          : status == 3
                          ? 'Production'
                          : 'Expiro'
                      }}
                    </div>
                  </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Fecha de expiracion" prop="date_expire" [width]="120"></ngx-datatable-column>
            <ngx-datatable-column name="Fecha de Creacion" prop="created_date" [width]="120"></ngx-datatable-column>
            <ngx-datatable-column name="Sedes" prop="created_date" [width]="120">
              <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                <div class="d-flex align-items-center">
                  <div class="cell-line-height">
                    <p class="font-medium-5 font-weight-bold line-height-1 mb-25">
                      {{ row.campus.length }}
                    </p>
                  </div>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Actions" [width]="120" [sortable]="false">
              <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                <div class="d-flex align-items-center">
                  <a (click)="editBusiness(row,modalForm)"> <i data-feather="edit" class="text-primary cursor-pointer" ></i></a>
                </div>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
     
                </div>
              </div>
            </div>
        </div>
          
        </section>
      <!--/ Kitchen Sink Datatables -->
  
    </div>
  </div>
  