import { Component, OnInit,ViewChild, ViewEncapsulation } from '@angular/core'
import { AngularFireDatabase, AngularFireList, AngularFireObject} from '@angular/fire/compat/database';
import { Device } from 'app/model/Device';
import { Log } from 'app/model/Log';
import { LogModel } from 'app/model/LogModel';
import { Subject,Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { DatatablesService } from 'app/services/datatables.service';
import * as snippet from 'app/main/sample/datatables.snippetcode';
import { Console } from 'console';
import * as admin from 'firebase-admin';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs } from 'file-saver';
 
 
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {

  logsRef: AngularFireList<any>;     
  logRef: AngularFireObject<any>;    
  watchRef: AngularFireObject<any>;    

 
  logs : Log
  logsData : Log[]
  allLog : LogModel[]
  logModel : LogModel

  
  //------------------------------

  private _unsubscribeAll: Subject<any>;
  private tempData = [];
  public rows: any;
  public exportCSVData;
  public ColumnMode = ColumnMode;
  public selected = [];
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('tableRowDetails') tableRowDetails: any;
  
  public _snippetCodeKitchenSink = snippet.snippetCodeKitchenSink;
  public _snippetCodeInlineEditing = snippet.snippetCodeInlineEditing;
  public _snippetCodeRowDetails = snippet.snippetCodeRowDetails;
  public _snippetCodeCustomCheckbox = snippet.snippetCodeCustomCheckbox;
  public _snippetCodeResponsive = snippet.snippetCodeResponsive;

  constructor(private sanitizer: DomSanitizer,private http: HttpClient,private db: AngularFireDatabase,private _datatablesService:DatatablesService,private auth : AngularFireAuth,private modalService: NgbModal) {
    this._unsubscribeAll = new Subject();

  }

// view variables
  public contentHeader: object
  public showReportBasic = true;
 
  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  currentDate : string;

  public tokenSelect : string;

 // private dbUrl : "https://isleeplab-default-rtdb.firebaseio.com/";
 // private dbUrl : "https://isleeplab-default-rtdb.firebaseio.com/logs/0x673c62b6717177689c04c9d2761372c1.json?auth=";
  private authToken ;
  async getTokenAuthUser(){
    (await this.auth.currentUser).getIdToken(true)
    .then((token) => {
        //console.log(token)
        this.authToken = token
    });
  }

  ngOnInit() {
  
    this.getTokenAuthUser();

    this.contentHeader = {
      headerTitle: 'Home',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Home',
            isLink: true,
            link: '/'
          },
          {
            name: 'Log History',
            isLink: false
          }
        ]
      }
    }
  
    // datatable 
     this._datatablesService.onDatatablessChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
      response.sort((n1) => { // order by las watch
       
        if(n1.lastWatch){
          return -1
        }
          return 1;
        
        })
       this.rows = response;
       this.tempData = this.rows;
       this.kitchenSinkRows = this.rows;
       this.exportCSVData = this.rows;
     });
  }

 

  rowDetailsToggleExpand(row) {
    this.tableRowDetails.rowDetail.toggleExpandRow(row);
     
  }
 
  GetLog(id: string){
    this.logRef = this.db.object('logs/'+id)
    return this.logRef
  }

// data table info
public kitchenSinkRows: any;
public basicSelectedOption: number = 100;
public SelectionType = SelectionType;

public filter = '1'
 
onItemChange(value){
  this.filter = value
  console.log(" Value is : ", value );
}

filterUpdate(event) {
  var filter = this.filter
  const val = event.target.value.toLowerCase();
  // filter our data
  const temp = this.tempData.filter(function (d) {
 
    if(filter == '1'){ // token
      if(d.tokenBand != null){
        return d.tokenBand.toLowerCase().indexOf(val) !== -1 || !val;
      }
    }
    if(filter == '2'){ //email
      if(typeof d.email !== "undefined"){
        return d.email.toLowerCase().indexOf(val) !== -1 || !val;
      }
    }
    if(filter == '3'){ //Mac
       if(typeof d.watchs !== "undefined"){
        return d.watchs[0].address.toLowerCase().indexOf(val) !== -1 || !val;
     }
    }
    
  });

  // update the rows
  this.kitchenSinkRows = temp;
  // Whenever the filter changes, always go back to the first page
  this.table.offset = 0;
}

SaveRemoteLog(remote : string,idPhone: string){
  const refDevice = this.db.object('device/'+idPhone+'/-DeviceDetails')
  refDevice.update({remoteLog:remote});
}
 
 download(urlfile : string){
   this.http.get(urlfile, {responseType: 'blob'}).subscribe(
    blob => {
        var theJSON = JSON.stringify(blob);
        var uri = this.sanitizer.bypassSecurityTrustUrl("data:text/json;charset=UTF-8," + encodeURIComponent(theJSON));
        saveAs(blob, "log.json")
    }
  )
}
 
onCheckboxChangeFn(row){
  let valueRemote = 'true'
  if(row.remoteLog == 'true'){
    valueRemote = 'false'
  }
  this.SaveRemoteLog(valueRemote,row.token)
 
}

 

  rowselect: Array<any> = [];
  modalInfoDevicesAll(modalInfo, row) {
    this.modalService.open(modalInfo, {
      centered: true,
      windowClass: 'modal modal-info',
      size: 'lg'
    });
    console.log(row)
    this.rowselect = row
  }

}
