<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- Kitchen Sink Datatables -->
    <section id="ngx-datatable-kitchen-sink">
      <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Device with log DATA 🚀</h4>
              </div>
                <div class="card-body pb-1">
                 
                  <div class="row">
                    <div class="col-md-6 col-12">
                      <div class="d-flex justify-content-between align-items-center m-1">
                        <label class="d-flex align-items-center"
                          >Show
                          <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                          entries</label
                        >
                      </div>
                    </div>
                    <div class="col-md-6 col-12 d-flex justify-content-md-end">
                      <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                        <div class="d-flex">
                          <div class="custom-control custom-radio">
                            <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input" [(ngModel)]="filter" value="1" checked
                            (change)="onItemChange($event.target.value)"/>
                            <label class="custom-control-label" for="customRadio1">Token</label>
                          </div>
                          <div class="custom-control custom-radio">
                            <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input" [(ngModel)]="filter" value="2" 
                            (change)="onItemChange($event.target.value)"/>
                            <label class="custom-control-label" for="customRadio2">Email</label>
                          </div>
                          <div class="custom-control custom-radio">
                            <input type="radio" id="customRadio3" name="customRadio" class="custom-control-input" [(ngModel)]="filter" value="3"
                            (change)="onItemChange($event.target.value)"/>
                            <label class="custom-control-label" for="customRadio3">Mac</label>
                          </div>
                        </div>
                        <label class="d-flex align-items-center"
                          ><input
                            type="search"
                            placeholder="Buscar"
                            class="form-control ml-25"
                            (keyup)="filterUpdate($event)"
                        /></label>
                        
                      </div>
                    </div>
                  </div>


            
                 <ngx-datatable
                  #tableRowDetails
                  [rows]="kitchenSinkRows"
                  [rowHeight]="65"
                  class="bootstrap core-bootstrap material"
                  [limit]="100"
                  [columnMode]="ColumnMode.standard"
                  [headerHeight]="40"
                  [footerHeight]="50"
                  [scrollbarH]="true"
                   [limit]="basicSelectedOption"
                >
                <!-- Row Detail Template -->
                  <ngx-datatable-row-detail [rowHeight]="100">
                    <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                      <div class="ml-75 pl-5 pt-75">
                        <div *ngIf="row.watchs; else noWatch">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="media">
                                <div class="avatar bg-light-warning rounded">
                                  <span class="avatar-content" style="height: 45px;">
                                    <i data-feather="watch" class="avatar-icon font-medium-3"></i>
                                  </span>
                                  <span class="avatar-status-online"></span>
                                </div>
  
                                <div class="media-body">
                                  <small class="font-weight-bolder">Info detail watch</small><br>
                                  <small class="mb-0">{{row.watchs[0].info}}</small><br>
                                </div>
                              </div>
                            </div>
                             
                             
                          </div>
                         </div>
                        
                        <ng-template #noWatch>
                          No Device
                        </ng-template>
                      </div>
                    </ng-template>
                  </ngx-datatable-row-detail>

                <ngx-datatable-column
                  [width]="50"
                  [resizeable]="false"
                  [sortable]="false"
                  [draggable]="false"
                  [canAutoResize]="false"
                >
                  <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                    <a
                      href="javascript:void(0)"
                      [class.datatable-icon-right]="!expanded"
                      [class.datatable-icon-down]="expanded"
                      title="Expand/Collapse Row"
                      (click)="rowDetailsToggleExpand(row)"
                    >
                    </a>
                  </ng-template>
                </ngx-datatable-column>
           
                      <ngx-datatable-column name="Log" prop="token" [width]="100">

                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                          <div class="d-flex align-items-center">
                            <div class="cell-line-height">
                              <a target="_blank" href="/log/{{ row.token }}" class="btn btn-outline-success round" rippleEffect><i data-feather="file-text"  ></i> </a>
                            </div>
                          </div>
                        </ng-template>

                      </ngx-datatable-column>

                      <ngx-datatable-column name="Token Device" prop="token" [width]="200">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                          <div class="d-flex align-items-center">
                            <div class="cell-line-height">
                              <p class="font-medium-1 font-weight-bold line-height-1 mb-25">
                                {{ row.brand }}
                              </p>
                              <span class="text-muted font-small-1" style="font-size: 9px !important"> {{ row.tokenBand }}</span>
                            </div>
                          </div>
                        </ng-template>
                      </ngx-datatable-column>

                      <ngx-datatable-column name="Device" prop="token" [width]="150">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                          <div class="d-flex align-items-center">
                            <div *ngIf="row.type == 'Tablet' else phone">
                              <div class="media">
                                <div class="avatar bg-light-primary rounded">
                                  <div class="avatar-content">
                                    <small>TAB</small>
                                  </div>
                                </div>
                                <div class="media-body">
                                  <h6 class="transaction-title"> {{ row.manufacturer }}</h6>
                                  <small>{{row.model}} </small> 
                                </div>
                              </div>
                            </div>
                            <ng-template #phone>
                              <div class="media">
                                <div class="avatar bg-light-primary rounded">
                                  <div class="avatar-content">
                                    <i data-feather="smartphone" class="avatar-icon font-medium-3"></i>
                                  </div>
                                </div>
                                <div class="media-body">
                                  <h6 class="transaction-title"> {{ row.manufacturer }}</h6>
                                  <small>{{row.model}} </small> 
                                </div>
                              </div>
                            </ng-template>
                            
                          </div>
                        </ng-template>
                      </ngx-datatable-column>

                      <ngx-datatable-column name="APP" prop="token" [width]="150">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                          <div class="d-flex align-items-center">
                            <div class="media">
                              <div class="avatar bg-light-success rounded">
                                <div class="avatar-content">
                                  APP
                                </div>
                              </div>
                              <div class="media-body">
                                <h6 class="transaction-title">Info APP</h6>
                                <small>v: {{row.appVersionName}} | OS : {{row.osVersion}}</small> 
                              </div>
                            </div>
                          </div>
                        </ng-template>
                      </ngx-datatable-column>
                       
                      <ngx-datatable-column name="Watch" prop="watchs" [width]="400">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                          <div *ngIf="row.type == 'Tablet' else phone">
                            <button type="button" class="btn btn-outline-info" (click)="modalInfoDevicesAll(modalInfo,row.watchs)"
                            rippleEffect>
                            <i data-feather="watch" class="avatar-icon font-medium-3"></i> Watchs
                          </button>
                          </div>
                          <ng-template #phone>
                            <div class="d-flex align-items-center">
                              <div *ngIf="row.watchs; else noWatch">
                               <div class="row">
                                 <div class="col-md-6">
                                   <div class="media">
                                     <div class="avatar bg-light-warning rounded">
                                       <span class="avatar-content" style="height: 45px;">
                                         <i data-feather="watch" class="avatar-icon font-medium-3"></i>
                                       </span>
                                       <div *ngIf="row.watchs[0].stateString == 'Conectado'">
                                         <span class="avatar-status-online"></span>
                                       </div>
                                       <div *ngIf="row.watchs[0].stateString == 'Conectando…'">
                                         <span class="avatar-status-offline"></span>
                                       </div>
                                       <div *ngIf="row.watchs[0].stateString == 'No conectado'">
                                         <span class="avatar-status-busy"></span>
                                       </div>
                                     </div>
       
                                     <div class="media-body">
                                       <small class="font-weight-bolder">{{row.watchs[0].address}}</small><br>
                                       <small class="mb-0">{{row.watchs[0].stateString}}</small><br>
                                     </div>
                                   </div>
                                 </div>
                                 <div class="col-md-4">
                                       <small><i data-feather="database"></i> {{row.watchs[0].lastSyncData | date: 'yyyy-MM-dd H:mm'}}</small><br>
                                       <small><i data-feather="server"></i> {{row.watchs[0].lastSyncServe | date: 'yyyy-MM-dd H:mm'}}</small><br>
                                 </div>
                                 <div class="col-md-1">
                                   <small><i data-feather="battery" class="font-medium-3"></i> {{row.watchs[0].batteryLevel}}%</small>
                                 </div>
                               </div>
                              </div>
                             
                             <ng-template #noWatch>
                               No Device
                             </ng-template>
                             
                           </div>
                          </ng-template>
                        </ng-template>
                      </ngx-datatable-column>
                   
                      <ngx-datatable-column name="email">
                      </ngx-datatable-column>
                   
                
                      <ngx-datatable-column name="Remote Log">
                        <ng-template let-row="row" let-name="value" let-isSelected="isSelected" ngx-datatable-cell-template>
                          <div class="custom-control custom-control-success custom-switch">
                            <input 
                             type="checkbox"
                             class="custom-control-input" 
                             [id]="row.token" 
                            
                             [checked]="(row.remoteLog == 'true')"
                             (change)="onCheckboxChangeFn(row)"
                             [name]="row.token"
                             />
                            <label class="custom-control-label" [for]="row.token"></label>
                          </div>
                        </ng-template>
                      </ngx-datatable-column>

                 </ngx-datatable>

                </div>
              </div>
            </div>
          </div>
 
    </section>
    <!--/ Kitchen Sink Datatables -->

  </div>
</div>


<!-- Modal Info waches-->
<ng-template #modalInfo let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="myModalLabel160">Informacion de Reloj</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>

    <ul>
      <li *ngFor="let watch of rowselect">


        <div class="align-items-center">
          <div *ngIf="watch; else noWatch">
            <div class="row">
              <div class="col-md-6">
                <div class="media">
                  <div class="avatar bg-light-warning rounded">
                    <span class="avatar-content" style="height: 45px;">
                      <i data-feather="watch" class="avatar-icon font-medium-3"></i>
                    </span>
                    <div *ngIf="watch.stateString == 'Conectado'">
                      <span class="avatar-status-online"></span>
                    </div>
                    <div *ngIf="watch.stateString == 'Conectando…'">
                      <span class="avatar-status-offline"></span>
                    </div>
                    <div *ngIf="watch.stateString == 'No conectado'">
                      <span class="avatar-status-busy"></span>
                    </div>
                  </div>

                  <div class="media-body">
                    <small class="font-weight-bolder">{{watch.address}}</small><br>
                    <small class="mb-0">{{watch.stateString}}</small><br>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <small><i data-feather="database"></i> {{watch.lastSyncData | date: 'yyyy-MM-dd H:mm'}}</small><br>
                <small><i data-feather="server"></i> {{watch.lastSyncServe | date: 'yyyy-MM-dd H:mm'}}</small><br>
                <small><i data-feather="clock"></i> {{watch.lastSync | date:'yyyy-MM-dd HH:mm'}}</small>
              </div>
              <div class="col-md-1">
                <small><i data-feather="battery" class="font-medium-3"></i> {{watch.batteryLevel}}%</small>
              </div>
            </div>
          </div>

          <ng-template #noWatch>
            No Device
          </ng-template>

        </div>
        <hr>
      </li>
    </ul>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-info" (click)="modal.close('Accept click')" rippleEffect>
      Accept
    </button>
  </div>
</ng-template>
<!-- / Modal -->
               