import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/compat/database';
import { ActivatedRoute } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Log } from 'app/model/Log';
import { LogModel } from 'app/model/LogModel';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-log-detail-v2',
  templateUrl: './log-detail-v2.component.html',
  styleUrls: ['./log-detail-v2.component.scss']
})
export class LogDetailV2Component implements OnInit {
  logsRef: AngularFireList<any>;     
  logRef: AngularFireObject<any>;    
 
  logs : Log
  logsData : Log[]
  allLog : LogModel[]
  logModel : LogModel
  public UID : string;
  public addressSelect : string;
  public currentDate : string;
  public basicDPdata: NgbDateStruct;

  constructor(private route: ActivatedRoute,private db: AngularFireDatabase, private auth : AngularFireAuth,private _toastrService: ToastrService,) { 
  }

  
  ngOnInit(): void {
   // this.getTokenAuthUser();
   this.route.paramMap.subscribe(params => {
    this.UID = params.get('idPhone');
    this.addressSelect = params.get('idAddress');
    console.log('URL:', this.UID );
    console.log('idAddress:', this.addressSelect);
    let date = new Date();
    this.currentDate  = (moment(date)).format('DD-MM-yyyy')
    this.GetLogByToken(this.addressSelect,this.UID) // core 
  });
  
  }
  GetLogByDate(){
    let date = new Date();
    date.setFullYear(this.basicDPdata.year)
    date.setMonth(this.basicDPdata.month-1)
    date.setDate(this.basicDPdata.day)

    this.currentDate = (moment(date)).format('DD-MM-yyyy')
    this.GetLogByToken(this.addressSelect,this.UID)// core
  }

  GetLogByToken(address:string, token :string){
    this.logsData = [];
    this.allLog = [];
 
    console.log('v2/logs/'+token+'/'+address+'/'+this.currentDate);
    
    let logs = this.logsRef = this.db.list(`v2/logs/${token}/${address}/${this.currentDate}`);
    logs.snapshotChanges().subscribe( data => {
      console.log(data);
       data.forEach(item => {
        let dataLog = item.payload.val()
        
        this.logs = {
          address:dataLog.address,
          message: dataLog.message,
          priority: dataLog.priority,
          tag: dataLog.tag,
          throwable: dataLog.throwable,
          time: dataLog.time,
        }
        this.logsData.push(this.logs)
       })

       if(data.length !=0){
        this.logModel = {
          date : this.currentDate,
          download: "https://isleeplab-default-rtdb.firebaseio.com/logs/"+token+"/"+this.currentDate+".json?auth="+this.authToken,
          logs : this.logsData
        }
         this.allLog.push(this.logModel)
         console.log(this.allLog)
       }else{
        this._toastrService.error(
          'No Data',
          'Error No hay Log'
        );
       }
        
      
    })

}


private authToken ;
async getTokenAu523thUser(){
  (await this.auth.currentUser).getIdToken(true)
  .then((token) => {
      //console.log(token)
      this.authToken = token
  });
}

}
