import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  {
    id: 'start',
    title: 'Home',
 
    type: 'item',
    icon: 'home',
    url: 'isleep/customers'
  },

  {
    id: 'pages',
    title: 'Adminitracion',
    type: 'collapsible',
    icon: 'settings',
    children: [
      {
        id: 'log',
        title: 'Log',
        translate: 'MENU.LOG',
        type: 'item',
        icon: 'smartphone',
        url: 'home'
      },
      {
        id: 'log',
        title: 'Log-v2',
        translate: 'MENU.LOG',
        type: 'item',
        icon: 'smartphone',
        url: 'log-v2'
      },
      {
        id: 'company',
        title: 'Empresas',
        translate: 'MENU.COMPANY',
        type: 'item',
        icon: 'layers',
        url: 'companies'
      },
      {
        id: 'users',
        title: 'Usuarios',
        translate: 'MENU.USERS',
        type: 'item',
        icon: 'users',
        url: 'users'
      },
      {
        id: 'devices',
        title: 'Relojes',
        
        type: 'item',
        icon: 'watch',
        url: 'devices'
      }
 
    ]
  },

 
]
