import { Component, EventEmitter, Injectable, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbCalendar, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { BusinessServiceService } from 'app/services/business-service.service';
import { Console } from 'console';
import moment from 'moment';
import { Subject } from 'rxjs';

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexStroke,
  ApexDataLabels,
  ApexXAxis,
  ApexGrid,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexPlotOptions,
  ApexYAxis,
  ApexFill,
  ApexMarkers,
  ApexTheme,
  ApexNonAxisChartSeries,
  ApexLegend,
  ApexResponsive,
  ApexStates
} from 'ng-apexcharts';

// interface ChartOptions
export interface ChartOptions {
  series?: ApexAxisChartSeries;
  chart?: ApexChart;
  xaxis?: ApexXAxis;
  dataLabels?: ApexDataLabels;
  grid?: ApexGrid;
  stroke?: ApexStroke;
  legend?: ApexLegend;
  title?: ApexTitleSubtitle;
  colors?: string[];
  tooltip?: ApexTooltip;
  plotOptions?: ApexPlotOptions;
  yaxis?: ApexYAxis;
  fill?: ApexFill;
  labels?: string[];
  markers: ApexMarkers;
  theme: ApexTheme;
}
export type ChartOptionPie = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  legend?: ApexLegend;
  colors?: string[];
  fill?: ApexFill;
};
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {

  readonly DELIMITER = '-';
  readonly DT_FORMAT = 'DD-MM-YYYY';
  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    if (!date) return '';
    let mdt = moment([date.year, date.month - 1, date.day]);
    if (!mdt.isValid()) return '';
    return mdt.format(this.DT_FORMAT);
  }
}



@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
  encapsulation: ViewEncapsulation.None,

  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter }
  ]

})
export class CustomersComponent implements OnInit {

  private tempData = [];

  // public
  public contentHeader: object;
  public rows: any[];
  public selected = [];
  public basicSelectedOption: number = 10;
  public ColumnMode = ColumnMode;
  public expanded = {};
  public editingName = {};
  public editingStatus = {};
  public editingAge = {};
  public editingSalary = {};
  public chkBoxSelected = [];
  public SelectionType = SelectionType;
  public exportCSVData = [];

  public apexLineAreaChart: Partial<ChartOptions>;
  public chartOptionPie: Partial<ChartOptionPie>;
  // select group
  public selectGroupselected = '';
  public selectGroup = [];
  public pinCodeValue = ''
  public isMenuToggled = false;
  public statisticsBar;
  public show: boolean = false;

  public moon = ''
  public totalTTS = ''
  public risk = ''

  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('tableRowDetails') tableRowDetails: any;

  columns
  destroy$: Subject<boolean> = new Subject<boolean>();
  dateSelected: string;
  dataSetChart: any;

  // Color Variables
  chartColors = {
    area: {
      series1: '#25CCF7',
      series2: '#FD7272',
    }
  };


  constructor(
    private businessService: BusinessServiceService,
    private ngbCalendar: NgbCalendar, private dateAdapter: NgbDateAdapter<string>,
    private modalService: NgbModal
  ) {

    this.initDateSelect()
  }

  ngOnInit(): void {
    this.businessService.getBusiness().then((value) => {
      this.selectGroup = value
    })
  }

  changeBusiness(item) {

    this.pinCodeValue = item.code_pairing
    this.selectGroupselected = item.name

    this.initResumenSleep()

  }


  initDateSelect() {
    this.dateSelected = this.today
  }

  @Output()
  selectDate = new EventEmitter<any>();
  change(event) {
    this.selectDate.emit(event);
    this.initResumenSleep()
  }

  get today() {
    return this.dateAdapter.toModel(this.ngbCalendar.getToday())!;
  }

  changeDate() {
    this.dateSelected = this.today
  }

  initResumenSleep() {
    this.businessService.getResumeSleepByCustomerPin(this.pinCodeValue, this.dateSelected)
      .then((response: any) => {
        this.rows = response

      }).catch((error) => {
        alert(error.error.message)
      })
  }




  viewCalendar() {
    this.show = !this.show;
  }

  //-------------datatables---------------

  filterUpdate(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.tempData.filter(function (d) {
      return d.full_name.toLowerCase().indexOf(val) !== -1 || !val;
    });
 
    this.table.offset = 0;
  }

 
  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);

    this.selected.splice(0, this.selected.length);
    this.selected.push(selected);
  }




  ngOnDestroy() {
    this.destroy$.next(true);
    // Unsubscribe from the subject
    this.destroy$.unsubscribe();
  }


  // MODAL set info  waches phone
  rowselect: Array<any> = [];
  modalOpenInfo(modalInfo, row) {
    this.modalService.open(modalInfo, {
      centered: true,
      windowClass: 'modal modal-info',
      size: 'lg'
    });
    console.log(row)
    this.rowselect = row
  }

  // CORE CHART OPEN : MODAL start methods
  blocks = []
  modalOpenInfoData(modalInfo, data) {
    console.log(data)
    this.blocks = []
    let starSleep;
    let endSleep;

    let totalSleep = 0;
    let totalAwake = 0;

    data.forEach(device => { // loop device
      const bloks = device.resume.blocks
      let sleepData = []
      const bloksData = Object.keys(bloks)
      console.log(bloksData.length)
      for (var key of bloksData) { // loop block sleep
        console.log(bloks[key])
        const jsonData = JSON.parse(bloks[key].data);
        sleepData.push({
          start: bloks[key].start,
          end: bloks[key].end,
          data: jsonData
        })

        totalSleep = totalSleep + bloks[key].sleep
        totalAwake = totalAwake + bloks[key].awake

        this.blocks.push(bloks[key])
      }


      starSleep = sleepData[0].start
      endSleep = sleepData[sleepData.length - 1].end

      this.moon = device.resume.moon
      this.risk = device.resume.riskLevel
      this.totalTTS = device.resume.hh + " h " + device.resume.mm + " min"

      this.dataSetChart = sleepData

    });


    this.apexLineAreaChart = {
      series: [
        {
          name: "Periodo",
          data: this.generateDataSets(1)
        }
      ],
      chart: {
        type: "bar",
        height: 200,
        animations: {
          enabled: false
        },
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          colors: {
            ranges: [
              {
                from: 35,
                to: 40,
                color: "#ff6b6b" //sleep
              },
              {
                from: 0,
                to: 30,
                color: "#48dbfb" //awake
              }
            ]
          },
          columnWidth: "100%"
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        type: "datetime",
        tickAmount: 8,
        //min: starSleep,
        //  max: endSleep,
        labels: {
          rotate: -70,
          rotateAlways: true,
          formatter: function (val, timestamp) {
            return moment(new Date(timestamp)).format("LT");
          }
        },
        categories: this.generateDataSets(2)
      },
      yaxis: {
        show: false
      },
      tooltip: {
        y: {
          formatter: (value) => {
            if (value == 35) { // awake
              return "awake"
            } else {
              return "Durmiendo"
            }

          },
        },
      }
    };


    var effSleep = (((totalSleep - totalAwake) / totalSleep) * 100)
    var effAwake = (100.0 - parseInt(effSleep.toString()))

    // pie chart
    this.chartOptionPie = {
      series: [parseInt(effSleep.toString()), effAwake],
      chart: {
        width: 180,
        height: 250,
        type: "pie"
      },
      labels: ["Sleep", "Awake"],
      colors: ['#48dbfb', '#ff6b6b'],
      legend: {
        position: 'bottom'
      }

    };


    // modal open 
    this.modalService.open(modalInfo, {
      centered: true,
      windowClass: 'modal modal-info',
      size: 'xl'
    });

  }


  public generateDataSets = function (t) {

    var dataSet = [];
    var axisX = [];
    this.dataSetChart.forEach(element => {

      element.data.forEach(item => {

        var seconds = item.seconds
        var minutes = (seconds / 60)
        var level = (item.level == 'awake') ? 35 : 30
        var timestamp = (new Date(item.datetime).getTime())
        for (var i = 0; i < minutes; i++) {
          timestamp = timestamp + 60000
          // console.log(new Date(timestamp))
          // console.log(type)
          dataSet.push({ x: timestamp, y: level })
          axisX.push(timestamp)
        }

      });

    });

    if (t == 1) {
      return dataSet
    } else {
      return axisX
    }
  };

}
