import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
 import {  takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Device } from '../../../services/response/Device';
import { ResponseDevice } from 'app/services/response/ResponseDevice';
 import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthModel } from 'app/model/AuthModel';
import { DeviceServiceService } from 'app/services/device-service.service';
@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DevicesComponent implements OnInit {

  private _unsubscribeAll: Subject<any>;
  private tempData = [];

  public Device  = {
    mac:"",
    token:"",
    number:"",
    serial:""
  };

  // public
  public contentHeader: object;
  public rows: Device[];
  public selected = [];
  public basicSelectedOption: number = 10;
  public ColumnMode = ColumnMode;
  public expanded = {};
  public editingName = {};
  public editingStatus = {};
  public editingAge = {};
  public editingSalary = {};
  public chkBoxSelected = [];
  public SelectionType = SelectionType;
  public exportCSVData = [];

  public auth: AuthModel;

  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('tableRowDetails') tableRowDetails: any;

 devices: Device[] = [];

 constructor(private deviceService: DeviceServiceService,private modalService: NgbModal) {
  this._unsubscribeAll = new Subject();
  }

 
 ngOnInit(): void {
   this.deviceService.onDatatablessChanged.pipe(takeUntil(this._unsubscribeAll))
   .subscribe(response => {
    this.rows = response;
    this.tempData = this.rows;
    this.devices = this.rows;
    this.exportCSVData = this.rows;
  });
   

  this.auth = new AuthModel();
}




//-------------datatables---------------

filterUpdate(event) {
 const val = event.target.value.toLowerCase();

 // filter our data
 const temp = this.tempData.filter(function (d) {
   return d.mac.toLowerCase().indexOf(val) !== -1 || !val;
 });

 // update the rows
 this.devices = temp;
 // Whenever the filter changes, always go back to the first page
 this.table.offset = 0;
}

 
onSelect({ selected }) {
 console.log('Select Event', selected, this.selected);
 this.selected.splice(0, this.selected.length);
 this.selected.push(selected);
}
 
  // modal Open Form
  modalOpenForm(modalForm) {
    this.modalService.open(modalForm,{
      size:'lg'
    });
  }

  registerDevice(){
    console.log(this.Device)
  }

}
