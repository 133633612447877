<div class="content-wrapper container-xxl p-0">
    
    <div class="content-body">
        <div class="card-body">
            <div class="row">
            <div class="col-md-6 col-12 mb-1">
                 <div class="form-group">
                    <div class="input-group">
                      <input class="form-control" placeholder="dd-mm-yyyy" name="Basic Date Picker" [(ngModel)]="basicDPdata"
                        ngbDatepicker #basicDP="ngbDatepicker" (dateSelect)="GetLogByDate()">
                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary feather icon-calendar" (click)="basicDP.toggle()"
                          type="button" rippleEffect></button>
                      </div>
                    </div>
                  </div>
               
            </div>
            </div>

            <!-- LOG VIEW -->
            <section id="home-page" *ngIf="allLog">
                <div class="row">
                <div class="col-12">
                    <div class="card">
                    <div class="card-header">
                        <span class="lead collapse-title ">
                            <h3><div class="badge badge-light-info">{{currentDate}} </div></h3>
                        </span>
                    </div>
                    <div class="card-body pb-1">

                        <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0"  *ngFor="let item of allLog">
                        <ngb-panel>
                            <ng-template ngbPanelTitle>
                            <span class="lead collapse-title ">
                                <h3><div class="badge badge-light-primary">{{item.date}} </div> <a (click)="download(item.download)"   href="javascript:void(0)" ><div class="badge badge-dark"><i data-feather="download"></i></div></a></h3>
                            </span>
                            </ng-template>
                            <ng-template ngbPanelContent>
                            
                            <ul class="timeline" *ngFor="let logs of item.logs">
                    
                                <li class="timeline-item">
                                <span class="timeline-point timeline-point-indicator" 
                                [ngClass]="logs.priority == 'WARN' ? 'timeline-point-warning' : logs.priority == 'INFO' ? 'timeline-point-success': logs.priority == 'ERROR' ? 'timeline-point-danger':'timeline-point-info'"
                                
                                ></span>
                                <div class="timeline-event">
                                    <div class="justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                    <h6><span class="timeline-event-time"><small>[{{logs.address}}] </small> {{logs.time}} TAG : {{logs.tag}} </span><p style="font-size: 13px;">{{logs.priority}} : {{logs.message}}</p> </h6>
                                    </div>
                                 </div>
                                </li>
                    
                            </ul>
                            
                            </ng-template>
                        </ngb-panel>
                        
                        </ngb-accordion>

                    </div>

                    </div>
                </div>
                </div>

            </section>
        </div>
    </div>
</div>