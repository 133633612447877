import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { ResponseBusiness } from './response/ResponseBusiness';
import { rejects } from 'assert';
import { ResponseCode } from './response/ResponseCode';
import { ResponseBusinessData } from './response/ResponseBusinessData';


@Injectable({
  providedIn: 'root'
})
export class BusinessServiceService implements Resolve<any> {

  private API_URL = environment.apiUrl;

  rows: any;
  response_code: any;
  onDatatablessChanged: BehaviorSubject<any>;

  constructor(private http: HttpClient, private db: AngularFireDatabase) {
    this.onDatatablessChanged = new BehaviorSubject({});
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getBusiness()]).then(() => {
        resolve();
      }, reject);
    });
  }
  
  public getBusinessID(id: String): Promise<ResponseBusinessData> {
    return new Promise((resolve,reject) => {
      this.http.get(this.API_URL+ '/business/' + id)
      .subscribe((response: ResponseBusinessData) => {
          resolve(response)
      },reject)
    })
  }
  public getNewCodeBusiness(): Promise<ResponseCode> {
    return new Promise((resolve,reject) => {
      this.http.get(this.API_URL+ '/business/pin/generate')
      .subscribe((response: ResponseCode) => {
          
          this.response_code = response
          resolve(this.response_code)
      },reject)
    })
  }
  public getBusiness(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.http.get(this.API_URL + '/business')
        .subscribe((response: ResponseBusiness) => {
          this.rows = response.data.business;
          this.onDatatablessChanged.next(this.rows)
          resolve(this.rows);
        }, reject)
    })
  }
  // Registrar un nuevo negocio
  public addBusiness(businessData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.API_URL + '/business', businessData)
        .subscribe((response: ResponseBusiness) => {
          const newBusiness = response
          resolve(newBusiness);
        }, reject)
    })
  }
  rowsAll: any[] = [];
  
  public getResumeSleepFireBaseByCustomerPin(pinCode, date) : Promise<any[]> {
      const deviceRef = this.db.list('device')
      const ref = deviceRef.query.orderByChild("customer").equalTo(pinCode)
    return new Promise(async (resolve, reject) => {
      ref.get().then((snapshot) => {
        snapshot.forEach((device) => {
          // console.log(device.key)
          const phone: any = device.val()["-DeviceDetails"]
          let watch: any = []
          watch = this.db.list('watch/' + device.key)
          watch.snapshotChanges().subscribe(data => {
            const watchData: any[] = []
            data.forEach(value => {
              const watch: any = value.payload.val()
  
              //console.log(watch)
              const resume = this.db.object('resume/' + device.key + '/' + date + '/' + watch.address)
              resume.snapshotChanges().subscribe(resumeData => {
                //console.log(resumeData.payload.val())
                watch.resume = resumeData.payload.val()
              })
              watchData.push(watch)
              phone.watchs = watchData
  
            })// end-loop-watch
            this.rowsAll.push(phone)
  
          })
        }) // end-loop-device(phone)
        //console.log(this.rowsAll)
        this.rows = this.rowsAll

        this.onDatatablessChanged.next(this.rows);
         
          resolve(this.rows)
        
      },reject)
    })
    
  }

  public getResumeSleepByCustomerPin(pinCode, date) : Promise<any[]> {

      return new Promise((resolve, reject) => {
        const phoneInfo = []
        const deviceRef = this.db.list('device')
        const ref = deviceRef.query.orderByChild("customer").equalTo(pinCode).get()
    
            ref.then((phoneData) => {
              phoneData.forEach( (data) => {
                const phone : any = data.val()["-DeviceDetails"]
                const watchRef = this.db.list('watch/' + data.key)
                watchRef.snapshotChanges().subscribe((snapshotWatch)=>{
                  const watchInfo = []
                  snapshotWatch.forEach((infoWatch)=>{
                     const watch :any = infoWatch.payload.val()
                     const resumeRef = this.db.object('resume/' + data.key + '/' + date + '/' + watch.address)
                     resumeRef.snapshotChanges().forEach((resumeData)=>{
                       const resume : any = resumeData.payload.val()
                       if(resume){
                        resume.hh = this.timeConvertHours(resume.minutesSleep)
                        resume.mm = this.timeConvertMinutes(resume.minutesSleep)
                        resume.moon = this.riskLevelImg(resume.minutesSleep)
                       }
                       
                       watch.resume = resume
                       watchInfo.push(watch)
                     })
                     phone.watch = watchInfo
                  })
                })
                
                phoneInfo.push(phone)
    
              })
              resolve(phoneInfo)
          },reject)
      
      })
  
  }

   timeConvertHours(n):string{
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
       return  rhours + "";
    }
    timeConvertMinutes(n):string{
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return rminutes+"";
    }

   riskLevelImg(minutesSleep): string{

      if (minutesSleep >= 330) { // 5h 30 min
        return 'moon_green.png'
      } else if (minutesSleep >= 270) { //  4h 30min <> 5h 30min
          return 'moon_yellow.png'
      } else if (minutesSleep >= 25) { //  > 4h 30min <>  25 min
          return 'moon_red.png'
      } else {
          return 'moon.png'
      }
  }


  // get and update device with AWS  services
  public updateDeviceFirebase(token, pincode) {
    const deviceRef = this.db.object('device');
    const device = deviceRef.query.orderByChild('-DeviceDetails/tokenBand').equalTo(token);
    device.get().then((snapshot) => {
      snapshot.forEach((deviceSnapshot) => {

        const deviceQuery = this.db.object('device/' + deviceSnapshot.key);
        deviceQuery.update({ customer: pincode }).then((snapshot) => {
          // console.log(deviceSnapshot.key);
          // alert("Asignado OK")
        });

      });
    });

  }

  public getDevice() {

  }

  public getUsersByBusiness(id: any): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.http.get(this.API_URL + '/user/bussines/' + id)
        .subscribe((response: any) => {
          this.rows = response;
          this.onDatatablessChanged.next(this.rows)
          resolve(this.rows);
        }, reject)
    })
  }


}
