<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <!-- content-header component -->
        <app-content-header [contentHeader]="contentHeader"></app-content-header>

        <!-- Kitchen Sink Datatables -->
        <section id="ngx-datatable-kitchen-sink">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">All Devices 🚀</h4>
                        </div>
                        <div class="card-body pb-1">

                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <div class="d-flex justify-content-between align-items-center m-1">
                                        <label class="d-flex align-items-center">Mostrar
                                            <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </label>

                                        <div *ngIf="selected.length > 0" class="col-6">
                                            <label> Asignar a Empresa</label>
                                            <div class="form-group">
                                                <!-- select size large -->
                                                <ng-select class="ng-select-size-lg" #SelectSizeLarge [items]="selectGroup" [searchable]="false"
                                                           bindLabel="name" bindValue="name" (change)="changeBusiness($event)">
                                                    <ng-template ng-header-tmp>
                                                        <input class="form-control w-100" type="text"
                                                               (input)="SelectSizeLarge.filter($event.target.value)" />
                                                    </ng-template>
                                                    <!--/ select size large -->
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-12 d-flex justify-content-md-end">
                                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                                        <div class="d-flex">
                                            <div class="custom-control custom-radio">
                                                <input type="radio" id="customRadio1" name="customRadio"
                                                       class="custom-control-input" [(ngModel)]="filter" value="1"
                                                       checked
                                                       (change)="onItemChange($event.target.value)"/>
                                                <label class="custom-control-label" for="customRadio1">Token</label>
                                            </div>
                                            <div class="custom-control custom-radio">
                                                <input type="radio" id="customRadio2" name="customRadio"
                                                       class="custom-control-input" [(ngModel)]="filter" value="2"
                                                       (change)="onItemChange($event.target.value)"/>
                                                <label class="custom-control-label" for="customRadio2">Email</label>
                                            </div>
                                            <div class="custom-control custom-radio">
                                                <input type="radio" id="customRadio3" name="customRadio"
                                                       class="custom-control-input" [(ngModel)]="filter" value="3"
                                                       (change)="onItemChange($event.target.value)"/>
                                                <label class="custom-control-label" for="customRadio3">Mac</label>
                                            </div>
                                        </div>
                                        <label class="d-flex align-items-center">
                                            <input
                                                type="search"
                                                placeholder="Buscar"
                                                class="form-control ml-25"
                                                (keyup)="filterUpdate($event)"/>
                                        </label>
                                    </div>
                                </div>
                            </div>


                            <ngx-datatable
                                #tableRowDetails
                                [rows]="kitchenSinkRows"
                                [rowHeight]="65"
                                class="bootstrap core-bootstrap material"
                                [columnMode]="ColumnMode.standard"
                                [headerHeight]="40"
                                [footerHeight]="50"
                                [scrollbarH]="true"
                                (select)="onSelect($event)"
                                [limit]="basicSelectedOption">

                                <ngx-datatable-column
                                        [width]="50"
                                        [sortable]="false"
                                        [canAutoResize]="false"
                                        [draggable]="false"
                                        [resizeable]="false">
                                    <ng-template
                                            ngx-datatable-header-template
                                            let-value="value"
                                            let-allRowsSelected="allRowsSelected"
                                            let-selectFn="selectFn">
                                        <div class="custom-control custom-checkbox">
                                            <input
                                                    type="checkbox"
                                                    class="custom-control-input"
                                                    [checked]="allRowsSelected"
                                                    (change)="selectFn(!allRowsSelected)"
                                                    id="headerChkbxRef"
                                            />
                                            <label class="custom-control-label" for="headerChkbxRef"></label>
                                        </div>
                                    </ng-template>
                                    <ng-template
                                            ngx-datatable-cell-template
                                            let-rowIndex="rowIndex"
                                            let-value="value"
                                            let-isSelected="isSelected"
                                            let-onCheckboxChangeFn="onCheckboxChangeFn">
                                        <div class="custom-control custom-checkbox">
                                            <input
                                                    type="checkbox"
                                                    class="custom-control-input"
                                                    [checked]="isSelected"
                                                    (change)="onCheckboxChangeFn($event)"
                                                    id="rowChkbxRef{{ rowIndex }}"
                                            />
                                            <label class="custom-control-label" for="rowChkbxRef{{ rowIndex }}"></label>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>

                                <ngx-datatable-column name="Token Device" prop="token" [width]="200">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        <div class="d-flex align-items-center">
                                            <div class="cell-line-height">
                                                <p class="font-medium-1 font-weight-bold line-height-1 mb-25">
                                                    {{ row.brand }}
                                                </p>
                                                <span class="text-muted font-small-1"
                                                      style="font-size: 9px !important"> {{ row.tokenBand }}</span>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>

                                <ngx-datatable-column name="Device" prop="token" [width]="150">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        <div class="d-flex align-items-center">
                                            <div *ngIf="row.type == 'Tablet' else phone">
                                                <div class="media">
                                                    <div class="avatar bg-light-primary rounded">
                                                        <div class="avatar-content">
                                                            <small>TAB</small>
                                                        </div>
                                                    </div>
                                                    <div class="media-body">
                                                        <h6 class="transaction-title"> {{ row.manufacturer }}</h6>
                                                        <small>{{row.model}} </small>
                                                    </div>
                                                </div>
                                            </div>
                                            <ng-template #phone>
                                                <div class="media">
                                                    <div class="avatar bg-light-primary rounded">
                                                        <div class="avatar-content">
                                                            <i data-feather="smartphone"
                                                               class="avatar-icon font-medium-3"></i>
                                                        </div>
                                                    </div>
                                                    <div class="media-body">
                                                        <h6 class="transaction-title"> {{ row.manufacturer }}</h6>
                                                        <small>{{row.model}} </small>
                                                    </div>
                                                </div>
                                            </ng-template>

                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>

                                <ngx-datatable-column name="APP" prop="token" [width]="150">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        <div class="d-flex align-items-center">
                                            <div class="media">
                                                <div class="avatar bg-light-success rounded">
                                                    <div class="avatar-content">
                                                        APP
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Info APP</h6>
                                                    <small>v: {{row.appVersionName}} | OS : {{row.osVersion}}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>

                                <ngx-datatable-column name="Watch" prop="watchs" [width]="400">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        <div *ngIf="row.type == 'Tablet' else phone">
                                            <button type="button" class="btn btn-outline-info"
                                                    (click)="modalInfoDevicesAll(modalInfo,row.watchs)"
                                                    rippleEffect>
                                                <i data-feather="watch" class="avatar-icon font-medium-3"></i> Watchs
                                            </button>
                                        </div>
                                        <ng-template #phone>
                                            <div class="d-flex align-items-center">
                                                <div *ngIf="row.watchs; else noWatch">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="media">
                                                                <div class="avatar bg-light-warning rounded">
                                                                   <span class="avatar-content" style="height: 45px;">
                                                                     <i data-feather="watch" class="avatar-icon font-medium-3"></i>
                                                                   </span>
                                                                    <div *ngIf="row.watchs[0].stateString == 'Conectado'">
                                                                        <span class="avatar-status-online"></span>
                                                                    </div>
                                                                    <div *ngIf="row.watchs[0].stateString == 'Conectando…'">
                                                                        <span class="avatar-status-offline"></span>
                                                                    </div>
                                                                    <div *ngIf="row.watchs[0].stateString == 'No conectado'">
                                                                        <span class="avatar-status-busy"></span>
                                                                    </div>
                                                                </div>
                                                                <div class="media-body">
                                                                    <small class="font-weight-bolder">{{row.watchs[0].address}}</small><br>
                                                                    <small class="mb-0">{{row.watchs[0].stateString}}</small><br>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <small><i
                                                                    data-feather="database"></i> {{row.watchs[0].lastSyncData | date: 'yyyy-MM-dd H:mm'}}
                                                            </small><br>
                                                            <small><i
                                                                    data-feather="server"></i> {{row.watchs[0].lastSyncServe | date: 'yyyy-MM-dd H:mm'}}
                                                            </small><br>
                                                        </div>
                                                        <div class="col-md-1">
                                                            <small><i data-feather="battery"
                                                                      class="font-medium-3"></i> {{row.watchs[0].batteryLevel}}
                                                                %</small>
                                                        </div>
                                                    </div>
                                                </div>

                                                <ng-template #noWatch>
                                                    No Device
                                                </ng-template>

                                            </div>
                                        </ng-template>
                                    </ng-template>
                                </ngx-datatable-column>

                                <ngx-datatable-column name="email">
                                </ngx-datatable-column>

                            </ngx-datatable>

                        </div>
                    </div>
                </div>
            </div>

        </section>
        <!--/ Kitchen Sink Datatables -->

    </div>
</div>
