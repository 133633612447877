import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ColumnMode, DatatableComponent, SelectionType} from '@swimlane/ngx-datatable';
import {DatatablesService} from 'app/services/datatables.service';
import {BusinessServiceService} from '../../../services/business-service.service';

@Component({
    selector: 'app-user-setting',
    templateUrl: './user-setting.component.html',
    styleUrls: ['./user-setting.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UserSettingComponent implements OnInit {


    private _unsubscribeAll: Subject<any>;
    private tempData = [];
    public rows: any;
    public exportCSVData;
    public ColumnMode = ColumnMode;
    public selected = [];
    @ViewChild(DatatableComponent) table: DatatableComponent;
    @ViewChild('tableRowDetails') tableRowDetails: any;

    public kitchenSinkRows: any;
    public basicSelectedOption = 100;
    public SelectionType = SelectionType;
    public filter = '2';
    public contentHeader: object;
    public showReportBasic = true;
    public selectGroupselected = '';
    public selectGroup = [];

    constructor(
        private _datatablesService: DatatablesService,
        private businessService: BusinessServiceService,
        private modalService: NgbModal) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.contentHeader = {
            headerTitle: 'Home',
            actionButton: true,
            breadcrumb: {
                type: '',
                links: [
                    {
                        name: 'Home',
                        isLink: true,
                        link: '/'
                    },
                    {
                        name: 'Log History',
                        isLink: false
                    }
                ]
            }
        };

        this.businessService.getBusiness().then((value) => {
            this.selectGroup = value;
        });

        this._datatablesService.getDataTableRowsJoin();

        this._datatablesService.onDatatablessChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
            this.rows = response;
            this.tempData = this.rows;
            this.kitchenSinkRows = this.rows;
            this.exportCSVData = this.rows;
        });
    }


    onSelect({selected}) {
       // console.log('Select Event', selected, this.selected);
        this.selected.splice(0, this.selected.length);
        this.selected.push(selected);
    }

    onItemChange(value) {
        this.filter = value;
        console.log(' Value is : ', value);
    }

    filterUpdate(event) {
      const filter = this.filter;
      const val = event.target.value.toLowerCase();
        // filter our data
      // update the rows
        this.kitchenSinkRows = this.tempData.filter(function (d) {

          if (filter === '1') { // token
            if (d.tokenBand != null) {
              return d.tokenBand.toLowerCase().indexOf(val) !== -1 || !val;
            }
          }
          if (filter === '2') { // email
            if (typeof d.email !== 'undefined') {
              return d.email.toLowerCase().indexOf(val) !== -1 || !val;
            }
          }
          if (filter === '3') { // Mac
            if (typeof d.watchs !== 'undefined') {
              return d.watchs[0].address.toLowerCase().indexOf(val) !== -1 || !val;
            }
          }

        });
        // Whenever the filter changes, always go back to the first page
        this.table.offset = 0;
    }

    changeBusiness(item) {
        const pinCodeValue = item.code_pairing;
        this.selectGroupselected = item.name;
        // @ts-ignore
       const next = confirm('Deseas Reasignar estos dispositivos a :' + this.selectGroupselected);
       if (next) {
           console.log(pinCodeValue);
           this.selected.forEach( (response) => {
               response.forEach( (device) => {
                   console.log(device);
                   this.businessService.updateDeviceFirebase(device.tokenBand, pinCodeValue);
               });
           });
       }
    }
}
