import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/compat/database';

@Injectable({
  providedIn: 'root'
})
export class FirebaseServiceService implements Resolve<any> {
  rows: any;
  rowsAll: any[];
  onDatatablessChanged: BehaviorSubject<any>;

   
  constructor(private db: AngularFireDatabase) {
    this.onDatatablessChanged = new BehaviorSubject({});
  }

   
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getDataTableRows()]).then(() => {
        resolve();
      }, reject);
    });
  }
 

  getDataTableRows(): Promise<any[]> {
    this.rowsAll = []
    return new Promise((resolve, reject) => {
      const  device = this.db.list('v2/device')
  

      device.snapshotChanges().subscribe(data => {
        data.forEach(item =>{
          //console.log(item.payload.val()["-DeviceDetails"])
          // item.payload.val()["-DeviceDetails"]
          this.rowsAll.push(item.payload.val()["-DeviceDetails"])
        })
        //console.log(this.rowsAll)
         this.rows = this.rowsAll
         this.onDatatablessChanged.next(this.rows);
         resolve(this.rows);
      }, reject);
    });
  }

  
  getWachesLog(UID): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const waches = this.db.list('v2/logs/' + UID);
      waches.snapshotChanges().subscribe(data => {
        const watchData: any[] = [];
        data.forEach(value => {
          const key = value.key;
          const watch: any = value.payload.val();
          watch.key = key;
          watch.UID = UID;
          watchData.push(watch);
        });
        console.log(watchData);
        this.onDatatablessChanged.next(watchData);
        resolve(watchData);
      }, reject);
    });
  }

  

  
  getDataTableRowsJoin(): Promise<any[]> {
    this.rowsAll = []
    return new Promise((resolve, reject) => {
      const  deviceRef = this.db.list('v2/device')
       
      deviceRef.valueChanges().pipe
      deviceRef.snapshotChanges().subscribe(data => {
        this.rowsAll.length = 0
        data.forEach(item =>{
            const device :  any = item.payload.val()["-DeviceDetails"]
            const  watch = this.db.list('v2/watch/'+item.key)
            
            watch.snapshotChanges().subscribe( data => {
              const watchData: any[] = []
              data.forEach( value => {
                const watch :any = value.payload.val()
                watchData.push(watch)
                device.watchs = watchData
               
              })
              
               
            })
            this.rowsAll.push(device)
        })
        
         this.rows = this.rowsAll
         
         this.onDatatablessChanged.next(this.rows);
         resolve(this.rows);
      }, reject);
    });
  }

  

}
