// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'isleeplab',
    appId: '1:1069961354894:web:2e87fe4992d560b898b3df',
    databaseURL: 'https://isleeplab-default-rtdb.firebaseio.com',
    storageBucket: 'isleeplab.appspot.com',
    apiKey: 'AIzaSyC0e_7IAlMCGTczR7ahY3lfSj8r34eaPyo',
    authDomain: 'isleeplab.firebaseapp.com',
    messagingSenderId: '1069961354894',
    measurementId: 'G-0M6LYF3F8V',
  },
  production: false,
  hmr: false,
  apiUrl: 'https://sleep.innobyte.pe/api',
  firebaseConfig : {
    apiKey: "AIzaSyC0e_7IAlMCGTczR7ahY3lfSj8r34eaPyo",
    authDomain: "isleeplab.firebaseapp.com",
    databaseURL: "https://isleeplab-default-rtdb.firebaseio.com",
    projectId: "isleeplab",
    storageBucket: "isleeplab.appspot.com",
    messagingSenderId: "1069961354894",
    appId: "1:1069961354894:web:2e87fe4992d560b898b3df",
    measurementId: "G-0M6LYF3F8V"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
