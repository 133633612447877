import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/compat/database';
import { ActivatedRoute } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Log } from 'app/model/Log';
import { LogModel } from 'app/model/LogModel';
import moment from 'moment';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class LogComponent implements OnInit {
  logsRef: AngularFireList<any>;     
  logRef: AngularFireObject<any>;    
 
  logs : Log
  logsData : Log[]
  allLog : LogModel[]
  logModel : LogModel
  
  public tokenSelect : string;
  public currentDate : string;
  public basicDPdata: NgbDateStruct;
  constructor(private _Activatedroute:ActivatedRoute,private db: AngularFireDatabase, private auth : AngularFireAuth) { }

  ngOnInit(): void {
   // this.getTokenAuthUser();
    this.tokenSelect = this._Activatedroute.snapshot.paramMap.get("idPhone")
    let date = new Date();
    this.currentDate  = (moment(date)).format('DD-MM-yyyy')
    this.GetLogByToken(this.tokenSelect) // core 
  }
  GetLogByDate(){
    let date = new Date();
    date.setFullYear(this.basicDPdata.year)
    date.setMonth(this.basicDPdata.month-1)
    date.setDate(this.basicDPdata.day)

    this.currentDate = (moment(date)).format('DD-MM-yyyy')
    this.GetLogByToken(this.tokenSelect)// core
  }

  GetLogByToken(token :string){
    this.logsData = [];
    this.allLog = [];

    let logs = this.logsRef = this.db.list('logs/'+token+'/'+this.currentDate);
    logs.snapshotChanges().subscribe( data => {
       data.forEach(item => {
        let dataLog = item.payload.val()
        //console.log(dataLog);
        this.logs = {
          address:dataLog.address,
          message: dataLog.message,
          priority: dataLog.priority,
          tag: dataLog.tag,
          throwable: dataLog.throwable,
          time: dataLog.time,
        }
        this.logsData.push(this.logs)
       })

       if(data.length !=0){
        this.logModel = {
          date : this.currentDate,
          download: "https://isleeplab-default-rtdb.firebaseio.com/logs/"+token+"/"+this.currentDate+".json?auth="+this.authToken,
          logs : this.logsData
        }
         this.allLog.push(this.logModel)
         console.log(this.allLog)
       }else{
         alert("No hay datos Log")
       }
        
      
    })

}


private authToken ;
async getTokenAu523thUser(){
  (await this.auth.currentUser).getIdToken(true)
  .then((token) => {
      //console.log(token)
      this.authToken = token
  });
}

}
