import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { NgbDateStruct, NgbDatepicker, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { BusinessServiceService } from 'app/services/business-service.service';
import { Business } from 'app/services/response/Business';
import { ResponseBusiness } from 'app/services/response/ResponseBusiness';
import { ResponseBusinessData } from 'app/services/response/ResponseBusinessData';
import { ResponseCode } from 'app/services/response/ResponseCode';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompaniesComponent implements OnInit {

  public Companies  = {
    name:"",
    expire_date:"",
    pairing_code:"",
    created_date:""
  };
  private _unsubscribeAll: Subject<any>;
  private tempData = [];

   // public
   public contentHeader: object;
   public rows: Business[];  
   public rows_business: Business[] = [];
   public rows_campus: Business[] = [];
   public selected = [];
   public basicSelectedOption: number = 10;
   public ColumnMode = ColumnMode;
   public editingName = {};
   public editingStatus = {};
   public editingAge = {};
   public editingSalary = {};
   public chkBoxSelected = [];
   public SelectionType = SelectionType;
   public exportCSVData = [];
   public type_deploy = 0;

   @ViewChild(DatatableComponent) table: DatatableComponent;
   @ViewChild('tableRowDetails') tableRowDetails: any;
   @ViewChild('tableRowGroups') tableRowGroups: any;

  business: Business[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedDate: NgbDateStruct;
  numMonths: any;
  hasParent: number;
  hasParentGroup: number;
  selectedCompanyId: number;
  id_campus = 0;
  id_group = 0;
  rows_group: Business[];

  
  constructor(private serviceBusiness: BusinessServiceService,private modalService: NgbModal,private db: AngularFireDatabase,private toast: ToastrService,private afAuth: AngularFireAuth) { 
    this._unsubscribeAll = new Subject();
  }

  
  ngOnInit(): void {
    this.serviceBusiness.onDatatablessChanged.pipe(takeUntil(this._unsubscribeAll))
    .subscribe(response => {
      this.rows = response;
      this.tempData = this.rows;
      this.rows_business = this.rows;
      this.business = this.rows;
      this.exportCSVData = this.rows;
    })
 }

  filterUpdate(event) {
    const val = event.target.value.toLowerCase();
  
    // filter our data
    const temp = this.tempData.filter(function (d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });
  
    // update the rows
    this.business = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }
  
  onActivate(event) {
    // console.log('Activate Event', event);
  }
 
    
    ngOnDestroy() {
      this.destroy$.next(true);
      // Unsubscribe from the subject
      this.destroy$.unsubscribe();
    }
    
    onGroupSelectionChange(event) {
      const selectedCompanyId = event.target.value;
       
      this.selectedCompanyId = selectedCompanyId

      if(this.hasParent ==1){ // onlu sede logic

      }else if(this.hasParent == 2){// group logic
          this.serviceBusiness.getBusinessID(selectedCompanyId).then((response:ResponseBusinessData) =>{
            this.id_campus = 0
            this.rows_group = response.business.campus
            if(this.rows_group.length == 0){
              alert("No hay Sedes a esta Empresa")
            }
          }).catch((error) => {
            alert(error)
          });
      }
      
    }

    editBusiness(row,modalForm){
      console.log(row);
      
      this.Companies.pairing_code = row.code_pairing
      this.Companies.created_date = row.created_date
      this.Companies.expire_date = row.date_expire
      this.Companies.name = row.name

      this.type_deploy = row.type_deploy
      this.id_campus = row.id_campus
      this.id_group = row.id_group

      this.numMonths = this.calcularDiferenciaMeses(row.created_date,row.date_expire)
      this.startModalBussiness(row,modalForm)
    }

    modalOpenForm(modalForm) {      
       this.serviceBusiness.getNewCodeBusiness().then((respuesta:ResponseCode) => {
        this.Companies.pairing_code = respuesta.code_unique
        this.startModalBussiness(respuesta,modalForm)
      }).catch((error) => {
       alert(error)
      });
    }

    startModalBussiness(respuesta,modalForm,){
      this.modalService.open(modalForm);
    }

    onDateSelect(date: NgbDateStruct, dp: NgbDatepicker) {
      this.selectedDate = date;
      if (this.numMonths) {
        const selectedDate = new Date(date.year, date.month - 1, date.day);
        const expirationDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + this.numMonths, selectedDate.getDate());
        // Utiliza la fecha de expiración como desees
       this.Companies.expire_date = this.formatDate(expirationDate)
       this.Companies.created_date = this.formatDate(selectedDate)
        console.log('Fecha de expiración:', expirationDate);
      }
    }
   
     calcularDiferenciaMeses(fechaInicio: string, fechaFin: string): number {
      const inicio = new Date(fechaInicio);
      const fin: Date = new Date(fechaFin);
    
      const diferenciaMs = fin.getTime() - inicio.getTime();
      const meses = Math.floor(diferenciaMs / (1000 * 60 * 60 * 24 * 30));
    
      return meses;
    }

    createBussiness(){
       this.modalService.dismissAll();
       const businessData = {
        name: this.Companies.name,
        code_pairing: this.Companies.pairing_code,
        expire_date: this.Companies.expire_date,
        type_deploy: this.type_deploy,
        id_campus: this.id_campus,
        id_group: this.id_group,
        created_date : this.Companies.created_date
      };
      console.log(businessData)

      this.serviceBusiness.addBusiness(businessData)
        .then(newBusiness => {
          console.log('Nuevo negocio agregado:', newBusiness);
          // crear usuario firebase
          this.afAuth.createUserWithEmailAndPassword(this.Companies.pairing_code+"@innobyte.pe", "$innobyte_" +this.Companies.pairing_code)
          .then((userCredential) => {
            // El usuario se creó exitosamente
            const user = userCredential.user;
            // Realiza cualquier acción adicional necesaria
          })
          .catch((error) => {
            // Ocurrió un error al crear el usuario
            const errorCode = error.code;
            const errorMessage = error.message;
            // Maneja el error de acuerdo a tus necesidades
          });
          alert("Se creo correctamente")
        })
        .catch(error => {
          console.error('Error al agregar negocio:', error.error);
          // Mostrar un mensaje de error al usuario
          alert('Error al agregar negocio: '+ error.error.message)
        });
         
    }

    rowDetailsToggleExpand(row) {
      this.tableRowDetails.rowDetail.toggleExpandRow(row);
    }
    rowDetailsGroupToggleExpand(row) {
      this.tableRowGroups.rowDetail.toggleExpandRow(row);
    }
    

    Device = {
        "address": "14:06:13:01:61:22",
        "authToken": "5194ab09cedf759efa61787ba71a9530",
        "lastActive": 1649851334,
        "firmwareVersion": "V1.0.0.46",
        "activeStatus": 1
    }
    insertDeviceAuth(){
      const refWaches = this.db.object('watches/'+this.Device.address)
      refWaches.set(this.Device)
    }

         
    padTo2Digits(num: number) {
      return num.toString().padStart(2, '0');
    }
     formatDate(date: Date) {
      return (
        [
          date.getFullYear(),
          this.padTo2Digits(date.getMonth() + 1),
          this.padTo2Digits(date.getDate()),
        ].join('-') +
        ' ' +
        [
          this.padTo2Digits(date.getHours()),
          this.padTo2Digits(date.getMinutes()),
          this.padTo2Digits(date.getSeconds()),
        ].join(':')
      );
    }
    

}
